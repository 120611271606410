import axios from "axios";

class ServiceClient {
  constructor() {
    axios.interceptors.request.use(
      function (config) {
        const jwtToken = localStorage.getItem("authorization");
        if (jwtToken) {
          config.headers["authorization"] = "Bearer " + jwtToken;
        }
        return config;
      },
      function (err) {
        return Promise.reject(err);
      }
    );
  }

  authenticate(data, callback, errorHandler) {
    axios.post("/api/authentication/authenticate", data).then((res) => {
      localStorage.setItem("authorization", res.data.jwttoken);
      callback();
    })
    .catch((e)=>errorHandler(e.response.data));
  }

  authenticateCustomer(data, callback, errorHandler) {
    axios.post("/api/authentication/authenticate-customer", data).then((res) => {
      localStorage.setItem("authorization", res.data.jwttoken);
      callback();
    })
    .catch((e)=>errorHandler(e.response.data));
  }

  saveFeedback(data, callback, errorHandler) {
    axios({
      method: "post",
      url: "/api/feedbacks/save",
      data: data,
    })
      .then(callback)
      .catch((e)=>{if(errorHandler) errorHandler(e.response.data);});
  }

  updateFeedbackStatus(params, callback) {
    axios({
        method: "get",
        url: "/api/feedbacks/update-status",
        params: params,
      })
        .then(callback)
  }


  getAllFeedbackIds(data, callback) {
    axios({
      method: "post",
      url: "/api/feedbacks/getFeedbackIds",
      data: data,
    }).then(callback);
  }

  getFeedbackDetails(params, callback) {
    axios({
        method: "get",
        url: "/api/feedbacks/get",
        params: params,
      }).then(callback);
  }

  getFeedbackSummary(params, callback) {
    axios({
        method: "get",
        url: "/api/feedbacks/summary",
        params: params,
      }).then(callback);
  }

  getRecentFeedbacks(params, callback) {
    axios({
        method: "get",
        url: "/api/feedbacks/recent",
        params: params,
      }).then(callback);
  }

  getFeedbackCustomers(params, callback) {
    axios({
        method: "get",
        url: "/api/feedbacks/getCustomers",
        params: params,
      }).then(callback);
  }

  getFeedbacksByUserId(params, callback) {
    axios({
        method: "get",
        url: "/api/feedbacks/getByUserId",
        params: params,
      }).then(callback);
  }

  updatEnterprise(data, callback, errorHandler) {
    console.log("Uploading file");
    axios({
      method: "post",
      url: "/api/enterprises/update",
      data: data,
    })
      .then(callback)
      .catch(errorHandler);
  }

  vote(params, callback) {
    axios({
      method: "get",
      url: "/api/feedbacks/vote",
      params: params,
    }).then(callback);
  }

  getVoters(params, callback) {
    axios({
      method: "get",
      url: "/api/feedbacks/voters",
      params: params
    }).then(callback)
  }

  comment(data, callback) {
    axios({
      method: "post",
      url: "/api/feedbacks/comment",
      data: data,
    }).then(callback);
  }

  signup(data, callback, errorHandler) {
    axios({
      method: "post",
      data: data,
      url: "/api/enterprise/signup",
    })
    .then(callback)
    .catch((e)=>errorHandler(e.response.data));
  }

  getUser(callback, errorHandler) {
    axios({
      method: "get",
      url: "/api/session/getUser",
    }).then(callback)
    .catch((error)=>{
      console.log("Get user failed ", error);
      if(errorHandler) errorHandler(error);
    });
  }

  updateUser(data, callback, errorHandler) {
    axios({
      method: "post",
      url:"/api/user/update",
      data: data,
    })
    .then(callback)
    .catch((error)=>{
      console.log("Update user failed ", error);
    });
  }

  updatePassword(data, callback, errorHandler) {
    axios({
      method: "post",
      url:"/api/user/update-password",
      data: data,
    })
    .then(callback)
    .catch((error)=>{
      console.log("Update password failed ", error);
      if(errorHandler) errorHandler(error.response.data);
    });
  }

  getEnterprise(subdomain, callback) {
    axios({
      method: "get",
      url: "/api/enterprise/get",
      params: {
        subdomain: subdomain
      }
    }).then(callback)
      .catch((error)=>{
        console.log("Get user failed ", error);
      });
  }

  getEnterpriseFromUser(params, callback) {
    axios({
      method: "get",
      url: "/api/enterprise/getFromUser",
      params: params
    }).then(callback)
    .catch((error)=>{
      console.log("Get user failed ", error);
    });
  }

  updateEnterprise(data, callback) {
      axios({
        method: "post",
        url:"/api/enterprise/update",
        data: data,
      })
      .then(callback)
      .catch((error)=>{
        console.log("Get user failed ", error);
      });
  }

  checkoutSubscription(data, callback) {
    axios({
      method: "post",
      url:"/api/payment/create-checkout-session",
      data: data,
    })
        .then(callback)
        .catch((error)=>{
          console.log("Checkout failed ", error);
        });
  }
}

export default new ServiceClient();