import React from 'react';
import {Fragment, useState } from 'react';
import { Dialog, Transition} from '@headlessui/react';
import {
  PhotographIcon,
  ViewGridIcon,
  CogIcon,
  HomeIcon,
  LogoutIcon,
  CashIcon,
  XIcon,
} from '@heroicons/react/outline';
import SettingsPage from './SettingsPage';
import FeedbacksPage from "./FeedbacksPage";
import HomePage from './HomePage';
import UsersPage from './UsersPage';
import { Link } from 'react-router-dom';

import { Route, Switch } from "react-router-dom";
import BillingPage from './BillingPage';


const sidebarNavigation = [
  { name: 'Home', href: 'home', icon: HomeIcon },
  { name: 'Feedbacks', href: 'feedbacks', icon: ViewGridIcon },
  { name: 'Users', href: 'users', icon: PhotographIcon },
  { name: 'Settings', href: 'settings', icon: CogIcon },
  { name: 'Billing', href: 'billing', icon: CashIcon },
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function NavigationPage(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const currentPage = props.match.params.page;
  const commonProps = {
    'setSidebarOpen': setSidebarOpen,
    'setMobileMenuOpen': setMobileMenuOpen,
  }

  return (
      <div className="h-screen overflow-hidden">
        <div className="h-full flex">
          {/*Side bar*/}

           {/* Narrow sidebar */}
           <div className="hidden w-28 bg-green-800 overflow-y-auto md:block">
            <div className="w-full py-6 flex flex-col items-center">
              <div className="flex-shrink-0 flex items-center">
                <img
                  className="h-14 w-auto"
                  src="/jf-transparent.svg"
                  alt="Workflow"
                />
              </div>
              <div className="flex-1 mt-6 w-full px-2 space-y-1">
                {sidebarNavigation.map((item) => (
                  <Link
                    key={item.name}
                    to={"/"+item.href}
                    className={classNames(
                      item.href === currentPage ? 'bg-green-700 text-white' : 'text-green-100 hover:bg-green-700 hover:text-white',
                      'group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium'
                    )}
                    aria-current={item.href === currentPage ? 'page' : undefined}
                  >
                    <item.icon
                      className={classNames(
                        item.href === currentPage ? 'text-white' : 'text-green-300 group-hover:text-white',
                        'h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    <span className="mt-2">{item.name}</span>
                  </Link>
                ))}
              </div>
            </div>

             <div className="absolute bottom-0">
               <Link
                 key="Logout"
                 to={"/logout"}
                 className='text-green-100 hover:bg-green-700 hover:text-white group w-28 p-3 rounded-md flex flex-col items-center text-xs font-medium'
               >
                 <LogoutIcon
                   className='text-green-300 group-hover:text-white h-6 w-6'
                   aria-hidden="true"
                 />
                 <span className="mt-2">Logout</span>
               </Link>
             </div>
          </div>

          {/* Mobile menu */}
          <Transition.Root show={mobileMenuOpen} as={Fragment}>
            <Dialog as="div" className="md:hidden" onClose={setMobileMenuOpen}>
              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                </Transition.Child>
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <div className="relative max-w-xs w-full bg-green-700 pt-5 pb-4 flex-1 flex flex-col">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-1 right-0 -mr-14 p-1">
                        <button
                          type="button"
                          className="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none"
                          onClick={() => setMobileMenuOpen(false)}
                        >
                          <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                          <span className="sr-only">Close sidebar</span>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 px-4 flex items-center">
                    </div>
                    <div className="mt-5 flex-1 h-0 px-2 overflow-y-auto">
                      <nav className="h-full flex flex-col">
                        <div className="space-y-1">
                          {sidebarNavigation.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className={classNames(
                                item.current
                                  ? 'bg-green-800 text-white'
                                  : 'text-green-100 hover:bg-green-800 hover:text-white',
                                'group py-2 px-3 rounded-md flex items-center text-sm font-medium'
                              )}
                              aria-current={item.current ? 'page' : undefined}
                            >
                              <item.icon
                                className={classNames(
                                  item.current ? 'text-white' : 'text-green-300 group-hover:text-white',
                                  'mr-3 h-6 w-6'
                                )}
                                aria-hidden="true"
                              />
                              <span>{item.name}</span>
                            </a>
                          ))}
                          <div className="absolute bottom-0">
                            <a
                              key="Logout"
                              href="logout"
                              className='text-green-100 hover:bg-green-800 hover:text-white group py-2 px-3 rounded-md flex items-center text-sm font-medium'
                            >
                              <LogoutIcon
                                className='text-green-300 group-hover:text-white mr-3 h-6 w-6'
                                aria-hidden="true"
                              />
                              <span>Logout</span>
                            </a>
                          </div>
                        </div>
                      </nav>
                    </div>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 w-14" aria-hidden="true">
                  {/* Dummy element to force sidebar to shrink to fit close icon */}
                </div>
              </div>
            </Dialog>
          </Transition.Root>

          {/* Content area */}

          {/*----------------------*/}
          <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
              <Switch>
                <Route
                  exact
                  path="/home"
                  render={() => <HomePage {...commonProps}/>}
                />

                <Route
                  exact
                  path="/users"
                  render={() => <UsersPage {...commonProps}/>}
                />

                <Route
                  exact
                  path="/feedbacks"
                  render={() => <FeedbacksPage {...commonProps}/>}
                />

                <Route
                  exact
                  path="/settings"
                  render={(routerProps) => <SettingsPage {...routerProps} {...commonProps}/>}
                />

                <Route
                  path="/settings/:page"
                  render={(routerProps) => <SettingsPage {...routerProps} {...commonProps}/>}
                />

                <Route
                    exact
                    path="/billing"
                    render={(routerProps) => <BillingPage {...routerProps} {...commonProps}/>}
                />

                <Route
                  path="/billing/:page"
                  render={(routerProps) => <BillingPage {...routerProps} {...commonProps}/>}
                />


                <Route render={() => <HomePage {...commonProps}/>} />
              </Switch>
          </div>
        </div>
      </div>
  );
}
