import React, { useEffect } from 'react';

import {Fragment, useState, useContext } from 'react';
import { Dialog, Transition, Menu } from '@headlessui/react';
import {
  DownloadIcon,
  TrashIcon,
  PencilIcon,
  LockOpenIcon,
  ChatAltIcon,
  ExternalLinkIcon,
  TagIcon,
  CalendarIcon,
  CheckCircleIcon,
  PlusIcon,
  SaveAsIcon,
  SaveIcon,
  ChevronDownIcon,
  UserCircleIcon as UserCircleIconSolid,
} from '@heroicons/react/outline';

import { Switch } from '@headlessui/react'
import serviceClient from "../helpers/ServiceClient";
import { format } from 'date-fns/esm';
import {UserContext} from "../components/UserContext";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


  export default function Feedback(props) {

    const [enabled, setEnabled] = useState(false)
    const [feedback, setFeedback] = useState(null);
    const [newStatus, setNewStatus] = useState(null);
    const [comment, setComment] = useState("");
    const [voters, setVoters] = useState([]);
    const userContext = useContext(UserContext);

    useEffect(()=>{
      console.log(props.feedbackId);
      refreshFeedback();
      refreshVoters();
    }, [props.feedbackId]);

    const changeStatus = () => {
      if(newStatus != null && props.feedbackId && feedback != null && newStatus != feedback.status) {
        serviceClient.updateFeedbackStatus({feedbackId: props.feedbackId, status: newStatus},
          ()=>{
            refreshFeedback();
          });
      }
    };

    const refreshFeedback = () => {
        serviceClient.getFeedbackDetails({feedbackId: props.feedbackId}, (response)=>{
          setFeedback(response.data);
          setNewStatus(response.data.status);
        });
    };

    const refreshVoters = () => {
      serviceClient.getVoters({feedbackId: props.feedbackId}, (response) => {
        setVoters(response.data);
      });
    }

    const commentOnFeedback = () => {
      if(comment) {
        serviceClient.comment({content: comment, userId: userContext.id, feedbackId: props.feedbackId},
          ()=>{
            setComment("");
            refreshFeedback();
          });
      }
    }

    const downloadVoters = () => {
        const rows = [
            ["name1", "city1", "some other info"],
            ["name2", "city2", "more info"]
        ];
        
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += "Name, Email\r\n";
        voters.forEach(function(voter) {
            let row = voter.fullName + "," + voter.email;
            csvContent += row + "\r\n";
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "my_data.csv");
        document.body.appendChild(link); // Required for FF

        link.click(); // This will download the data file named "my_data.csv".
    }

    if(!props.feedbackId) {
      return (
        <div className='flex justify-center items-center h-screen w-full '>
          <div className="text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
              />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900">No feedbacks</h3>
            <p className="mt-1 text-sm text-gray-500">Get started by creating a new feedback.</p>
            <div className="mt-6">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none"
              >
                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                New Feedback
              </button>
            </div>
          </div>
        </div>
      );

    } else if(!feedback) {
      return (
        <div className='flex justify-center items-center h-screen w-full '></div>
      );
    }

      return (
        <main className="flex-1">
            <div className="py-8 xl:py-10">
              <div className="px-4 sm:px-6 lg:px-8 xl:grid xl:grid-cols-3">
                <div className="xl:col-span-2 xl:pr-8 xl:border-r xl:border-gray-200 h-screen overflow-y-scroll">
                  <div>
                    <div>
                      <div className="md:flex md:items-center md:justify-between md:space-x-4 xl:border-b xl:pb-6">
                        <div>
                          <h1 className="text-2xl font-bold text-gray-900">{feedback.title}</h1>
                          <p className="mt-2 text-sm text-gray-500">
                            #{feedback.id} opened by{' '}
                            <a href="#" className="font-medium text-gray-900">
                              {feedback.fullName}
                            </a>{' '}
                            in{' '}
                            <a href="#" className="font-medium text-gray-900">
                              {feedback.boardName}
                            </a>
                          </p>
                        </div>
                        <div className="mt-4 flex space-x-3 md:mt-0">
                          <button
                            type="button"
                            className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                          >
                            <TrashIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                            <span>Delete</span>
                          </button>
                          <a
                            type="button"
                            href={'feedback/'+feedback.id}
                            target='_blank'
                            className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                          >
                            <ExternalLinkIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                            <span>Link</span>
                          </a>
                        </div>
                      </div>
                      {feedback && 
                      <aside className="mt-8 xl:hidden">
                        <h2 className="sr-only mb-6">Details</h2>
                        <div className="space-y-5 mt-6">
                          <div className="flex items-center space-x-2">
                            <LockOpenIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
                            <span className="text-green-700 text-sm font-medium">{feedback.status} Issue</span>
                          </div>
                          <div className="flex items-center space-x-2">
                            <ChatAltIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            <span className="text-gray-900 text-sm font-medium">{feedback.comments ? feedback.comments.length : 0} comments</span>
                          </div>
                          <div className="flex items-center space-x-2">
                            <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            <span className="text-gray-900 text-sm font-medium">
                              Created on <time dateTime="2020-12-02">Dec 2, 2020</time>
                            </span>
                          </div>
                        </div>
                        <div className="mt-6 border-t border-b border-gray-200 py-6 space-y-8">
                          <div>
                            <h2 className="text-sm font-medium text-gray-500">Assignees</h2>
                            <ul role="list" className="mt-3 space-y-3">
                              <li className="flex justify-start">
                                <a href="#" className="flex items-center space-x-3">
                                  <div className="flex-shrink-0">
                                    <img
                                      className="h-5 w-5 rounded-full"
                                      src="https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                                      alt=""
                                    />
                                  </div>
                                  <div className="text-sm font-medium text-gray-900">Eduardo Benz</div>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div>
                            <h2 className="text-sm font-medium text-gray-500">Tags</h2>
                            <ul role="list" className="mt-2 leading-8">
                              <li className="inline">
                                <a
                                  href="#"
                                  className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5"
                                >
                                  <div className="absolute flex-shrink-0 flex items-center justify-center">
                                    <span className="h-1.5 w-1.5 rounded-full bg-rose-500" aria-hidden="true" />
                                  </div>
                                  <div className="ml-3.5 text-sm font-medium text-gray-900">Bug</div>
                                </a>{' '}
                              </li>
                              <li className="inline">
                                <a
                                  href="#"
                                  className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5"
                                >
                                  <div className="absolute flex-shrink-0 flex items-center justify-center">
                                    <span className="h-1.5 w-1.5 rounded-full bg-green-500" aria-hidden="true" />
                                  </div>
                                  <div className="ml-3.5 text-sm font-medium text-gray-900">Accessibility</div>
                                </a>{' '}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </aside>}
                      <div className="py-3 xl:pt-6 xl:pb-0">
                        <h2 className="sr-only">Description</h2>
                        <div className="prose max-w-none">
                          <p>
                            {feedback.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <section aria-labelledby="activity-title" className="mt-8 xl:mt-10">
                    <div>
                      <div className="divide-y divide-gray-200">
                        <div className="pb-4">
                          <h2 id="activity-title" className="text-lg font-medium text-gray-900">
                            Activity
                          </h2>
                        </div>
                        <div className="pt-6">
                          {/* Activity feed*/}
                          <div className="flow-root">
                            <ul role="list" className="-mb-8">
                              {feedback.comments && feedback.comments.map((comment, itemIdx) => (
                                <li key={itemIdx}>
                                  <div className="relative pb-8">
                                    {itemIdx !== feedback.comments.length - 1 ? (
                                      <span
                                        className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                                        aria-hidden="true"
                                      />
                                    ) : null}
                                    <div className="relative flex items-start space-x-3">
                                      <div className="relative">
                                        <img
                                          className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"
                                          src={'/api/user/profilePic/' + comment.userId}
                                          alt=""
                                        />

                                        <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
                                          <ChatAltIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                      </div>
                                      <div className="min-w-0 flex-1">
                                        <div>
                                          <div className="text-sm">
                                            <a href={comment.fullName} className="font-medium text-gray-900">
                                              {comment.fullName}
                                            </a>
                                          </div>
                                          <p className="mt-0.5 text-sm text-gray-500">Commented on {format(new Date(comment.createdOn), "MMM dd, yyyy")}</p>
                                        </div>
                                        <div className="mt-2 text-sm text-gray-700">
                                          <p>{comment.content}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className="mt-6 h-80">
                            <div className="flex space-x-3">
                              <div className="flex-shrink-0">
                                <div className="relative">
                                  <img
                                    className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"
                                    src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                                    alt=""
                                  />

                                  <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
                                    <ChatAltIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                  </span>
                                </div>
                              </div>
                              <div className="min-w-0 flex-1">
                                <div>
                                  <div>
                                    <label htmlFor="comment" className="sr-only">
                                      Comment
                                    </label>
                                    <textarea
                                      id="comment"
                                      name="comment"
                                      rows={3}
                                      onChange={(e)=>{setComment(e.target.value)}}
                                      className="shadow-sm block w-full focus:ring-gray-900 focus:border-gray-900 sm:text-sm border border-gray-300 rounded-md"
                                      placeholder="Leave a comment"
                                      value={comment}
                                    />
                                  </div>
                                  <div className="mt-6 flex items-center justify-end space-x-4">
                                    <button
                                      type="button"
                                      className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                                    >
                                      <CheckCircleIcon
                                        className="-ml-1 mr-2 h-5 w-5 text-green-500"
                                        aria-hidden="true"
                                      />
                                      <span>Close issue</span>
                                    </button>
                                    <button
                                      onClick={commentOnFeedback}
                                      className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-900 hover:bg-black focus:outline-none"
                                    >
                                      Comment
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <aside className="hidden xl:block xl:pl-4">
                  <h3 className="mb-4 font-medium">Details</h3>
                  <div className="space-y-5">
                    <div className="flex items-center space-x-2">
                      <LockOpenIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
                      <span className="text-green-700 text-sm font-medium">{feedback.status}</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <ChatAltIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span className="text-gray-900 text-sm font-medium">{feedback.comments.length} comments</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span className="text-gray-900 text-sm font-medium">
                        Created on <time dateTime={format(new Date(feedback.createdOn), "yyyy-MM-dd")}>{format(new Date(feedback.createdOn), "MMM dd, yyyy")}</time>
                      </span>
                    </div>
                  </div>
                  <div className="w-56 mt-6 border-t border-gray-200 py-6 space-y-8">
                    <div>
                      <h3 className="mb-4  font-medium">Voters</h3>
                      <ul role="list" className="w-48 columns-3 mt-3 mb-4 space-y-3">
                        {voters.map((v, idx)=>
                          <li key={idx} className="flex justify-start">
                          <a href="#" className="flex items-center space-x-3">
                            <div className="flex-shrink-0">
                            <div class="group">
                              <img
                                className="h-10 w-10 rounded-full"
                                src={"/api/user/profilePic/" + v.id}
                                alt=""
                              />
                              <span className="absolute hidden group-hover:block bg-neutral-400 rounded text-white p-1 text-sm">{v.fullName}</span>
                            </div>
                            </div>
                            {/* <div className="text-sm font-medium text-gray-900">Eduardo Benz</div> */}
                          </a>
                        </li>
                        )}
                      </ul>
                      <button
                            onClick={downloadVoters}
                            type="button"
                            className="w-48 inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                          >
                            <DownloadIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                            <span>Download List</span>
                          </button>
                    </div>



                    <div className='bg-white shadow sm:rounded-lg p-4 w-56'>
                      <h3 className='mb-4  font-medium'>Status Update</h3>

                      <Switch.Group as="div" className="mt-4 flex items-center">
                      <Switch
                        checked={enabled}
                        onChange={setEnabled}
                        className={classNames(
                          enabled ? 'bg-green-800' : 'bg-gray-200',
                          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            enabled ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                          )}
                        />
                      </Switch>
                      <Switch.Label as="span" className="ml-3">
                        <span className="text-sm font-medium text-gray-900">Email Voters</span>
                      </Switch.Label>
                    </Switch.Group>

                      <div className='mt-4 flex columns-2'>
                        <Menu as="div" className="relative w-32">
                        <Menu.Button className="w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">
                          {newStatus}
                          <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </Menu.Button>
                        <Menu.Items className="origin-bottom-right z-10 absolute right-0 bottom-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item onClick={()=>setNewStatus("Open")}>
                                <a href="#" className='bg-gray-100 text-gray-900 block px-4 py-2 text-sm'>
                                  Open
                                </a>
                            </Menu.Item>
                            <Menu.Item  onClick={()=>setNewStatus("Under Review")}>
                            <a href="#" className='bg-gray-100 text-gray-900 block px-4 py-2 text-sm'>
                                  Under Review
                                </a>
                            </Menu.Item>
                            <Menu.Item onClick={()=>setNewStatus("Planned")}>
                              <a href="#" className='bg-gray-100 text-gray-900 block px-4 py-2 text-sm'>
                                  Planned
                              </a>
                            </Menu.Item>
                            <Menu.Item onClick={()=>setNewStatus("In Progress")}>
                              <a href="#" className='bg-gray-100 text-gray-900 block px-4 py-2 text-sm'>
                                  In Progress
                                </a>
                            </Menu.Item>
                            <Menu.Item onClick={()=>setNewStatus("Completed")}>
                              <a href="#" className='bg-gray-100 text-gray-900 block px-4 py-2 text-sm'>
                                Completed
                               </a>
                            </Menu.Item>
                            <Menu.Item onClick={()=>setNewStatus("Closed")}>
                              <a href="#" className='bg-gray-100 text-gray-900 block px-4 py-2 text-sm'>
                                 Closed
                              </a>
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Menu>

                       <div className='items-right ml-2'>
                        <button
                          onClick={changeStatus}
                          type="button"
                          className="inline-flex justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-800 hover:bg-green-700 focus:outline-none">
                              Save
                        </button>
                      </div>
                    </div>

                   

                      
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </main>
      );
  }