import React from 'react';
import { Link } from 'react-router-dom';
import {
  PhotographIcon,
  CashIcon,
  CogIcon,
  ViewGridAddIcon,
} from '@heroicons/react/outline';
import BrandingSettings from '../components/BrandingSettings';

import { Route, Switch, Redirect } from "react-router-dom";

import DomainSettings from '../components/DomainSettings';
import AccountSettings from '../components/AccountSettings';
import BillingPage from './BillingPage';
import DefaultHeader from "../components/DefaultHeader";

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


  export default function SettingsPage(props) {

    console.log(props);

    const subNavigation = [
        {
            name: 'Branding',
            description: 'Choose your brand name, brand logo and color.',
            href: '/settings/branding',
            icon: PhotographIcon,
            current: props.match.params!= null && props.match.params.page === 'branding',
        },
        {
            name: 'Domain',
            description: 'Choose your subdomain or your own domain name.',
            href: '/settings/domain',
            icon: ViewGridAddIcon,
            current: props.match.params!= null && props.match.params.page === 'domain',
          },
        {
          name: 'Account',
          description: 'Set your account details, name, address and password.',
          href: '/settings/account',
          icon: CogIcon,
          current: props.match.params!= null && props.match.params.page === 'account',
        },
        // {
        //   name: 'Billing',
        //   description: 'Orci aliquam arcu egestas turpis cursus. Lectus faucibus netus dui auctor mauris.',
        //   href: '/settings/billing',
        //   icon: CashIcon,
        //   current: props.match.params!= null && props.match.params.page === 'billing',
        // },
      ];

      return (
        <>
          <DefaultHeader setMobileMenuOpen={props.setMobileMenuOpen} setSideBarOpen={props.setSidebarOpen}/>
        <div className="flex-1 relative z-0 flex overflow-hidden">

        {/* <BrowserRouter> */}
            <Switch>

            <Route
                exact
                path="/settings"
                render={() => <Redirect to="/settings/branding" />}
              />

            <Route
                path="/settings/branding"
                render={() =><BrandingSettings />}
            />

            <Route
                path="/settings/domain"
                render={() => <DomainSettings />}
            />

            <Route
                path="/settings/account"
                render={() => <AccountSettings />}
            />

            {/* <Route
                path="/settings/billing"
                render={() => <BillingSettings />}
            /> */}
            </Switch>
        {/* </BrowserRouter> */}

            {/* Secondary sidebar */}
            <nav
                  aria-label="Sections"
                  className="hidden flex-shrink-0 w-96 bg-white border-r border-blue-gray-200 xl:flex xl:flex-col"
                >
                  <div className="flex-shrink-0 h-16 px-6 border-b border-blue-gray-200 flex items-center">
                    <p className="text-lg font-medium text-blue-gray-900">Settings</p>
                  </div>
                  <div className="flex-1 min-h-0 overflow-y-auto">
                    {subNavigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current ? 'bg-blue-50 bg-opacity-50' : 'hover:bg-blue-50 hover:bg-opacity-50',
                          'flex p-6 border-b border-blue-gray-200'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        <item.icon className="flex-shrink-0 -mt-0.5 h-6 w-6 text-blue-gray-400" aria-hidden="true" />
                        <div className="ml-3 text-sm">
                          <p className="font-medium text-blue-gray-900">{item.name}</p>
                          <p className="mt-1 text-blue-gray-500">{item.description}</p>
                        </div>
                      </Link>
                    ))}
                  </div>
                </nav>

            
          </div>
          </>
      );
  }