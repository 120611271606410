import React, { useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import serviceClient from '../helpers/ServiceClient';
import { EnterpriseContext } from './EnterpriseContext';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


export default function DomainSettings() {

  const [enterprise, setEnterprise] = useState({});
  const [domain, setDomain] = useState(null);

  useEffect(()=>{
    refreshEnterprise();
  }, []);

  const refreshEnterprise = () => {
    serviceClient.getEnterpriseFromUser({}, (response)=>{
      setEnterprise(response.data);
    });
  };

  const clear = () => {
    setDomain(null);
  }

  const save = () => {
    var formData = new FormData();
    formData.append("enterpriseId", enterprise.id);
    if(domain) formData.append("domain", domain);
    serviceClient.updateEnterprise(formData, ()=>{
      toast("Updated domain settings!!!")
      clear();
    });
  }
  

  return (
      <main className="h-screen flex-1 relative z-0 overflow-y-scroll focus:outline-none xl:order-last">
          <div className="flex-1 xl:overflow-y-auto">
                <div className="max-w-3xl mx-auto py-10 px-4 sm:px-6 lg:py-12 lg:px-8">
                  <h1 className="text-3xl font-extrabold text-blue-gray-900">Domain</h1>

                  <div className="mt-6 divide-y divide-y-blue-gray-200">
                    <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">

                    <div className="sm:col-span-6">
                        <label htmlFor="subdomain" className="block text-sm font-medium text-blue-gray-900">
                          Subdomain
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          
                          <input
                            value={enterprise.subdomain}
                            disabled={true}
                            type="text"
                            name="subdomain"
                            id="subdomain"
                            autoComplete="subdomain"
                            defaultValue=""
                            className="flex-1 block rounded-l-md w-full min-w-0 border-blue-gray-900 text-gray-700 sm:text-sm focus:outline-none focus:ring-green-900 focus:border-green-900 bg-gray-100"
                          />
                          <span className="inline-flex items-center px-3 rounded-none rounded-r-md  border border-r-0 border-blue-gray-900 bg-blue-gray-900 text-blue-gray-500 sm:text-sm">
                            .justfeedback.co
                          </span>
                        </div>
                      </div>

                      <div className="sm:col-span-6">
                        <label htmlFor="domain" className="block text-sm font-medium text-blue-gray-900">
                          Mapped domain name
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          
                          <input
                            value={domain ? domain : enterprise.domain}
                            onChange={(e)=>setDomain(e.target.value)}
                            type="text"
                            name="domain"
                            id="domain"
                            autoComplete="domain"
                            defaultValue=""
                            className="flex-1 block rounded-md w-full min-w-0 border-blue-gray-900 text-gray-700 sm:text-sm focus:outline-none focus:ring-green-900 focus:border-green-900"
                          />
                        </div>
                      </div>
                      
                    </div>


                    <div className="pt-8 flex justify-end mb-6">
                      <button
                        onClick={clear}
                        type="button"
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-blue-gray-900 hover:bg-blue-gray-50 focus:outline-none focus:ring-green-900 focus:border-green-900"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={save}
                        type="submit"
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-800 hover:bg-green-700 focus:outline-none focus:ring-green-900 focus:border-green-900"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Domain Mapping</h3>
                      <p className="mt-1 max-w-2xl text-sm text-gray-500">Follow these instructions to map your domain to this subdomain.</p>
                    </div>
                    <div className="border-t border-gray-200">
                      <dl>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">About</dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur
                            qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud
                            pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
                          </dd>
                        </div>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">Full name</dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">Margot Foster</dd>
                        </div>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">Application for</dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">Backend Developer</dd>
                        </div>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">Email address</dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">margotfoster@example.com</dd>
                        </div>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">Salary expectation</dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">$120,000</dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
          </main>
  );
}