import React, {useEffect, useState} from 'react';
import ErrorBanner from '../components/ErrorBanner';
import serviceClient from "../helpers/ServiceClient";
import {useHistory} from 'react-router-dom';
import OAuth2Login from 'react-simple-oauth2-login';
import {TEST_MODE} from '../config/Constants';
import GithubLogin from '../components/GithubLogin';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function LoginPage(props) {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [loginData, setLoginData] = useState(
    localStorage.getItem('loginData')
      ? JSON.parse(localStorage.getItem('loginData'))
      : null
  );

  const handleFailure = (result) => {
    console.log(result);
  };

  const handleOauthLogin = (accessToken, provider) => {
    serviceClient.authenticate(
      {
        token: accessToken,
        provider: provider
      },
      async () => {
        console.log("Login successful");
        await props.loginCallback();
        history.push("/home");
      },
      (e) => {
        setErrorMsg(e.message);
      }
    );
  }

  const handleLoginGoogle = async (googleData) => {
    console.log(googleData);
    handleOauthLogin(googleData.access_token, "GOOGLE");
  };

  const handleLoginTwitter = async (googleData) => {
    console.log(googleData);
    handleOauthLogin(googleData.access_token, "TWITTER");
  };

  useEffect(() => {
    serviceClient.getUser(() => {
      history.push("/home");
    }, () => {
    });
  }, []);

  const login = async (e) => {
    e.preventDefault();
    serviceClient.authenticate(
      {
        username: email,
        password: password
      },
      async () => {
        console.log("Login successful");
        await props.loginCallback();
        history.push("/home");
      },
      (e) => {
        setErrorMsg(e.message);
      }
    );
  };

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src="/jf-icon.svg"
          alt="Workflow"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Or{' '}
          <a href="/register" className="text-lg text-green-600 hover:text-green-500">
            start your 14-day free trial
          </a>
        </p>
      </div>

      <ErrorBanner msg={errorMsg}/>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={login}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value)
                  }}
                  autoComplete="current-password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded"
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <a href="/recover" className="font-medium text-green-600 hover:text-green-500">
                  Forgot your password?
                </a>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                Sign in
              </button>
            </div>
          </form>

          <form className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"/>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">Or continue with</span>
              </div>
            </div>

            <div className="mt-6 grid grid-cols-3 gap-3">
              <div>
                <OAuth2Login
                  render={renderProps => (
                    <a onClick={renderProps.onClick} disabled={renderProps.disabled}
                       className="cursor-pointer w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                      <span className="sr-only">Sign in with Google</span>
                      <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 32 32">
                        <path
                          d="M 16.003906 14.0625 L 16.003906 18.265625 L 21.992188 18.265625 C 21.210938 20.8125 19.082031 22.636719 16.003906 22.636719 C 12.339844 22.636719 9.367188 19.664063 9.367188 16 C 9.367188 12.335938 12.335938 9.363281 16.003906 9.363281 C 17.652344 9.363281 19.15625 9.96875 20.316406 10.964844 L 23.410156 7.867188 C 21.457031 6.085938 18.855469 5 16.003906 5 C 9.925781 5 5 9.925781 5 16 C 5 22.074219 9.925781 27 16.003906 27 C 25.238281 27 27.277344 18.363281 26.371094 14.078125 Z"></path>
                      </svg>
                    </a>
                  )}
                  authorizationUrl="https://accounts.google.com/o/oauth2/v2/auth"
                  scope="email profile"
                  onSuccess={handleLoginGoogle}
                  onFailure={handleFailure}
                  responseType="token"
                  redirectUri={TEST_MODE ? "https://tolocalhost.com/login" : "https://justfeedback.co/login"}
                  cookiePolicy={'single_host_origin'}
                  clientId="89153020289-i7udeu91qa4c7p09g0cfd30lh0ho4q1i.apps.googleusercontent.com"/>
              </div>

              <div>
                <div>
                  <OAuth2Login
                    render={renderProps => (
                      <a
                        onClick={renderProps.onClick} disabled={renderProps.disabled}
                        className="cursor-pointer w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      >
                        <span className="sr-only">Sign in with Twitter</span>
                        <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                          <path
                            d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84"/>
                        </svg>
                      </a>
                    )}
                    authorizationUrl="https://twitter.com/i/oauth2/authorize"
                    scope="email profile"
                    onSuccess={handleLoginTwitter}
                    onFailure={handleFailure}
                    responseType="token"
                    redirectUri={TEST_MODE ? "https://tolocalhost.com/login" : "https://justfeedback.co/login"}
                    cookiePolicy={'single_host_origin'}
                    clientId="a2FfRl9QNm1aRkc2SlhjV3RoV2s6MTpjaQ"/>
                </div>
              </div>

              <div>
                <GithubLogin/>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}