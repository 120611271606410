import React, { useContext, useEffect } from 'react';
import { subDays, format, endOfDay } from 'date-fns';
import {useState } from 'react';
import { Menu } from '@headlessui/react';
import {
  SortAscendingIcon,
  ChevronDownIcon,
  FilterIcon,
} from '@heroicons/react/outline';
import Feedback from '../components/Feedback';
import serviceClient from "../helpers/ServiceClient";
import FeedbackItem from '../components/FeedbackItem';
import { EnterpriseContext } from '../components/EnterpriseContext';
import Pagination from '../components/Pagination';
import FeedbacksHeader from "../components/FeedbacksHeader";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file


  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


  export default function FeedbackPage(props) {
    const [feedbackIds, setFeedbackIds] = useState([]);
    const [selectedFeedback, setSelectedFeedback] = useState(0);
    const [filterByStatus, setFilterByStatus] = useState("All");
    const [openDateFilter, setOpenDateFilter] = useState(false);
    const [dateRange, setDateRange] = useState({
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: 'selection',
    });

    const ITEMS_PER_PAGE = 10;
    const FILTER_BY_STATUSES = [
      "All",
      "Open",
      "Planned",
      "In Progress",
      "Completed",
      "Rejected"
    ];
    const [currentPage, setCurrentPage] = useState(0);
    
    const [allItem, setAllItems] = useState([]);
    const [pageCount, setPageCount] = useState(2);
    const [itemOffset, setItemOffset] = useState(0);

    const enterprise = useContext(EnterpriseContext);

    const handlePageChange = (pageNumber) => {
      console.log("Event selected is " + pageNumber);
      if (!allItem.length) return;
      const newOffset = (pageNumber * ITEMS_PER_PAGE) % allItem.length;
      console.log(
        `User requested page number ${pageNumber}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
      setFeedbackIds(allItem.slice(newOffset, newOffset + ITEMS_PER_PAGE));
    };

    useEffect(()=>{
      getFeedbacks();
    }, [enterprise, filterByStatus, dateRange]);

    const getFeedbacks = () => {
      console.log("This is executing too...");
      const startTime = dateRange.startDate.getTime();
      const endTime = endOfDay(dateRange.endDate).getTime();

      console.log(enterprise);
      if(enterprise && enterprise.subdomain) {
        const request = {
          subdomain: enterprise.subdomain,
          status: filterByStatus,
          startTime: startTime,
          endTime: endTime
        };
        serviceClient.getAllFeedbackIds(request,
          (response)=>{
            console.log(response.data);
            const feedbackIds = response.data.slice(itemOffset, itemOffset+ITEMS_PER_PAGE);
            setAllItems(response.data);
            console.log(feedbackIds);
            setFeedbackIds(feedbackIds);
            if (feedbackIds[0]) {
              setSelectedFeedback(feedbackIds[0]);
            }
          });
      }
    };

    const onNewFeedback = () => {
      console.log("This is executing");
      getFeedbacks();
    };

      return (
        <>
          <FeedbacksHeader setMobileMenuOpen={props.setMobileMenuOpen} setSideBarOpen={props.setSidebarOpen} onNewFeedback={onNewFeedback}/>
        <div className="flex-1 relative z-0 flex overflow-hidden">
          
            {/* Secondary sidebar */}
            <nav
                  aria-label="Sections"
                  className="hidden flex-shrink-0 w-[475px] bg-white border-r border-blue-gray-200 xl:flex xl:flex-col"
                >
              <div className="bg-white lg:min-w-0 lg:flex-1">
                <div className="pl-2 pr-2 pt-2 pb-2 border-b border-t border-gray-200 sm:pl-2 lg:pl-4 xl:pl-2 xl:pt-2 xl:border-t-0">
                  <div className="flex items-center">
                    <h1 className="flex-1 text-lg font-medium">Feedbacks</h1>

                    <div className="relative ml-1">
                      <button
                        className="w-full bg-white border border-gray-300 rounded-md shadow-sm px-2 py-2 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                        onClick={()=>setOpenDateFilter(!openDateFilter)}
                      >
                        <FilterIcon className="mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                        {format(dateRange.startDate, 'dd/MM/yy') + '-' + format(dateRange.endDate, 'dd/MM/yy')}
                        <ChevronDownIcon className="ml-1 -mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                      </button>
                      {openDateFilter && <div className="origin-top-right z-10 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <DateRangePicker
                          showSelectionPreview={true}
                          editableDateInputs={true}
                          moveRangeOnFirstSelection={false}
                          staticRanges={[]}
                          inputRanges={[]}
                          months={1}
                          direction="horizontal"
                          ranges={[dateRange]} onChange={item => {setDateRange(item.selection);}}/>
                      </div>}
                    </div>

                    <Menu as="div" className="relative ml-1">
                      <Menu.Button className="w-full bg-white border border-gray-300 rounded-md shadow-sm px-4 py-2 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">
                        <FilterIcon className="mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                        Status: {filterByStatus}
                        <ChevronDownIcon className="ml-1 -mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                      </Menu.Button>
                      <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {FILTER_BY_STATUSES.map((status)=>(
                            <Menu.Item onClick={()=>setFilterByStatus(status)}>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'block px-4 py-2 text-sm'
                                  )}
                                >
                                  {status}
                                </a>
                              )}
                            </Menu.Item>
                          ))
                          }
                        </div>
                      </Menu.Items>
                    </Menu>
                  </div>
                </div>
                <div className='h-screen overflow-y-scroll'>
                  <ul role="list" className="relative z-0 divide-y divide-gray-200 border-b border-gray-200">
                    {feedbackIds.map((feedbackId, idx) => (
                      <FeedbackItem onClick={()=>setSelectedFeedback(feedbackId)} selected={feedbackId == selectedFeedback} key={idx} feedbackId={feedbackId} />
                    ))}
                  </ul>
                  <div className='h-48'>
                     <Pagination totalItems={allItem.length} itemsPerPage={ITEMS_PER_PAGE} onPageChange={handlePageChange}/>
                  </div>
                </div>
              </div>
            </nav>
            
            <Feedback feedbackId={selectedFeedback} onNewFeedback={onNewFeedback}/>
            
          </div>
          </>
      );
  }