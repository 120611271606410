import React, { useEffect, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css'

export default function ImageCrop({ src, onImageCrop, onCancel }) {
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({
    unit: '%', // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50,
    aspect: 1,
  });
  const [pixelCrop, setPixelCrop] = useState(null);

  const finalizeCrop = ()=>{
    if (onImageCrop) {
      onImageCrop(getCroppedImg())
    }
  }

  const cancelCrop = ()=>{
    if(onCancel) {
      onCancel();
    }
  }

  const getCroppedImg = () => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');

    const pixelRatio = window.devicePixelRatio;
    canvas.width = pixelCrop.width * pixelRatio;
    canvas.height = pixelCrop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      pixelCrop.x * scaleX,
      pixelCrop.y * scaleY,
      pixelCrop.width * scaleX,
      pixelCrop.height * scaleY,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height,
    );

    return canvas;
  };

  return (
    <div className="absolute left-0 top-0 w-full h-full bg-gray-400 bg-opacity-50">
      <div className="m-0 m-auto w-1/2 h-1/2">
        <ReactCrop crop={crop} src={src}
                   onChange={(c, percentCrop) => {setCrop(percentCrop);}}
                   onImageLoaded={setImage}
                   onComplete={(c, percentCrop) => {setCrop(percentCrop); setPixelCrop(c);}}>
        </ReactCrop>
        <button
          onClick={finalizeCrop}
          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-800 hover:bg-green-700"
        >
          Crop
        </button>
        <button
          onClick={cancelCrop}
          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-800 hover:bg-green-700"
        >
          Cancel
        </button>
      </div>
    </div>
  )
}