import React, { useContext, useEffect, useState } from 'react';
import { format } from 'date-fns';
import Expand from 'react-expand-animated';
import serviceClient from "../helpers/ServiceClient";

import {
    XCircleIcon,
    ChevronDownIcon,
    ChevronUpIcon
} from '@heroicons/react/outline';
import { UserContext } from './UserContext';

import { Dialog, Transition } from '@headlessui/react'
import { toast } from 'react-toastify';

import { Fragment, useRef } from 'react'
import { Menu, Popover } from '@headlessui/react';
import {
  ChatAltIcon,
  CodeIcon,
  DotsVerticalIcon,
  EyeIcon,
  FlagIcon,
  PlusSmIcon,
  SearchIcon,
  ShareIcon,
  StarIcon,
} from '@heroicons/react/solid'
import {ThumbUpIcon } from '@heroicons/react/outline';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


export default function PublicFeedbackItem2(props) {

    const [open, setOpen] = useState(false);
    const [comment, setComment] = useState("");
    const [feedback, setFeedback] = useState({});

    const userContext = useContext(UserContext);

    const toggle = () => {
        setOpen(!open);
    };

    const submitComment = () => {
        if(userContext && userContext.email && userContext.fullName) {
            serviceClient.comment({
                feedbackId: feedback.id,
                userId: userContext.id,
                content: comment,
            }, ()=>{setComment(""); refreshFeedback()})
        } else {
            props.loginPopup();
        }
    };

    useEffect(()=>{
        refreshFeedback();
    }, [props.feedbackId, userContext]);

    const refreshFeedback = () => {
        serviceClient.getFeedbackDetails({feedbackId: props.feedbackId}, (response)=>{setFeedback(response.data)});
    };

    const vote = () => {
        if(userContext && userContext.email && userContext.fullName) {
            serviceClient.vote({
                feedbackId: feedback.id,
                userId: userContext.id
            }, ()=>{refreshFeedback()});
        } else {
            props.loginPopup();
        }
    }

    const renderThumbsUp = () => {
        if(!feedback) return (<div></div>);
        if(feedback.hasUserVoted) {
            return (
                <span
                 onClick={vote}
                 className={'border cursor-pointer text-purple-700 rounded-lg inline-flex p-3 ring-4 ring-white'}>
                    {/* <ThumbUpIcon className="h-6 w-6" aria-hidden="true" /> */}
                    <svg xmlns="http://www.w3.org/2000/svg" fill={props.theme.baseColor} viewBox="0 0 24 24" stroke={props.theme.baseColor} aria-hidden="true" class="h-6 w-6"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"></path></svg>
                </span>
            );
        } else {
            return (
                <span 
                onClick={vote}
                className={'border cursor-pointer text-purple-700 rounded-lg inline-flex p-3 ring-4 ring-white'}>
                    {/* <ThumbUpIcon className="h-6 w-6" aria-hidden="true" /> */}
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke={props.theme.baseColor} aria-hidden="true" class="h-6 w-6"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"></path></svg>
                </span>
            );
        }
    }

    return (
        <li key={feedback.id} className="bg-white px-4 py-6 shadow sm:p-6 sm:rounded-lg">
          <article aria-labelledby={'question-title-' + feedback.id}>
            <div>
              <div className="flex space-x-3">
                <div className="flex-shrink-0">
                    {renderThumbsUp()}
                </div>
                <div className="min-w-0 flex-1">
                  <p className="text-sm font-medium text-gray-900">
                    <a href={feedback.useId} className="hover:underline">
                      {feedback.fullName}
                    </a>
                  </p>
                  <p className="text-sm text-gray-500">
                    <a href={"?feedback=" + feedback.id} className="hover:underline">
                      {feedback.createdOn && <time dateTime={feedback.createdOn}>{format(new Date(feedback.createdOn), "MMM dd, yyyy")}</time>}
                    </a>
                  </p>
                </div>
                <div className="flex-shrink-0 self-center flex">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600">
                        <span className="sr-only">Open options</span>
                        <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'flex px-4 py-2 text-sm'
                                )}
                              >
                                <StarIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                <span>Add to favorites</span>
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'flex px-4 py-2 text-sm'
                                )}
                              >
                                <CodeIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                <span>Embed</span>
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'flex px-4 py-2 text-sm'
                                )}
                              >
                                <FlagIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                <span>Report content</span>
                              </a>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              <h2 id={'question-title-' + feedback.id} className="mt-4 text-base font-medium text-gray-900">
                {feedback.title}
              </h2>
            </div>
            <div
              className="mt-2 text-sm text-gray-700 space-y-4"
              dangerouslySetInnerHTML={{ __html: feedback.description }}
            />
            <div className="mt-6 flex justify-between space-x-8">
              <div className="flex space-x-6">
                <span className="inline-flex items-center text-sm">
                  <button onClick={vote} type="button" className="inline-flex space-x-2 text-gray-400 hover:text-gray-500">
                    <ThumbUpIcon className="h-5 w-5" aria-hidden="true" />
                    <span className="font-medium text-gray-900">{feedback.votes}</span>
                    <span className="sr-only">likes</span>
                  </button>
                </span>
                <span className="inline-flex items-center text-sm">
                  <button onClick={toggle} type="button" className="inline-flex space-x-2 text-gray-400 hover:text-gray-500">
                    <ChatAltIcon className="h-5 w-5" aria-hidden="true" />
                    {feedback.comments && <span className="font-medium text-gray-900">{feedback.comments.length}</span>}
                    <span className="sr-only">replies</span>
                  </button>
                </span>
                <span className="inline-flex items-center text-sm">
                  <button type="button" className="inline-flex space-x-2 text-gray-400 hover:text-gray-500">
                    <EyeIcon className="h-5 w-5" aria-hidden="true" />
                    <span className="font-medium text-gray-900">{feedback.views ? feedback.views : 0}</span>
                    <span className="sr-only">views</span>
                  </button>
                </span>
              </div>
              <div className="flex text-sm">
                <span className="inline-flex items-center text-sm">
                  <button type="button" className="inline-flex space-x-2 text-gray-400 hover:text-gray-500">
                    <ShareIcon className="h-5 w-5" aria-hidden="true" />
                    <span className="font-medium text-gray-900">Share</span>
                  </button>
                </span>
              </div>
            </div>
          </article>

          <Expand open={open}>
            {feedback.comments && 
            <div className="flow-root divide-y divide-gray-200">
                    <div className="pb-4 mt-6">
                        <h4 id="activity-title" className="text-md font-small text-gray-900">
                        Activity
                        </h4>
                    </div>

                    <div className="min-w-0 flex-1">
                      <div>
                        <div>
                          <label htmlFor="comment" className="sr-only">
                            Comment
                          </label>
                          <textarea
                            id="comment"
                            name="comment"
                            rows={3}
                            onChange={(e)=>{setComment(e.target.value)}}
                            className="shadow-sm block w-full focus:ring-gray-900 focus:border-gray-900 sm:text-sm border border-gray-300 rounded-md"
                            placeholder="Leave a comment"
                            value={comment}
                          />
                        </div>
                        <div className="mt-2 flex items-center justify-end space-x-4">
                          <button
                            onClick={submitComment}
                            className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-900 hover:bg-black focus:outline-none"
                          >
                            Comment
                          </button>
                        </div>
                      </div>
                    </div>

                    <ul role="list" className="-mb-8 pt-4 mt-4">
                    {feedback.comments.map((comment, itemIdx) => (
                        <li key={itemIdx}>
                        <div className="relative pb-8">
                            {itemIdx !== feedback.comments.length - 1 ? (
                            <span
                                className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                                aria-hidden="true"
                            />
                            ) : null}
                            <div className="relative flex items-start space-x-3">
                            <div className="relative">
                                <img
                                className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"
                                src={'/api/user/profilePic/' + comment.userId}
                                alt=""
                                />

                                <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
                                <ChatAltIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                            </div>
                            <div className="min-w-0 flex-1">
                                <div>
                                <div className="text-sm">
                                    <a href={comment.fullName} className="font-medium text-gray-900">
                                    {comment.fullName}
                                    </a>
                                </div>
                                <p className="mt-0.5 text-sm text-gray-500">Commented on {format(new Date(comment.createdOn), "MMM dd, yyyy")}</p>
                                </div>
                                <div className="mt-2 text-sm text-gray-700">
                                <p>{comment.content}</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </li>
                    ))}
                    </ul>


                    



                </div>}
            </Expand>
        </li>
    );
}