import React, { useContext, useEffect } from 'react';
import {Fragment, useState } from 'react';
import { Dialog, Transition, Menu, Popover } from '@headlessui/react';
import {
  MenuAlt2Icon,
  PlusSmIcon,
  MenuIcon,
  ExternalLinkIcon,
} from '@heroicons/react/outline';
import { SearchIcon } from '@heroicons/react/solid';
import serviceClient from "../helpers/ServiceClient";
import {EnterpriseContext} from "./EnterpriseContext";
import { toast } from 'react-toastify';
import {TEST_MODE} from '../config/Constants';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function FeedbacksHeader(props) {

  const [featureTitle ,setFeatureTitle] = useState("");
  const [featureDescription, setFeatureDescription] = useState("");
  const enterprise = useContext(EnterpriseContext);

  const submitFeedback = () => {
    serviceClient.saveFeedback({
        title: featureTitle,
        description: featureDescription
      },
      ()=>{
        toast("Feedback submitted");
        setFeatureTitle("");
        setFeatureDescription("");
        props.onNewFeedback();
      },
      (error)=>{
        toast.error(error.message)
      });
  }

  return (
    <>
    {/*Header */}
  <header className="w-full">
    <div className="relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex">
      <button
        type="button"
        className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none md:hidden"
        onClick={() => props.setMobileMenuOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className="flex-1 flex justify-between px-4 sm:px-6">
        <div className="flex-1 flex">
          <form className="w-full flex md:ml-0" action="#" method="GET">
            <label htmlFor="search-field" className="sr-only">
              Search
            </label>
            <div className="relative w-full text-gray-400 focus-within:text-gray-600">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                <SearchIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              </div>
              <input
                name="search-field"
                id="search-field"
                className="h-full w-full border-transparent py-2 pl-8 pr-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none"
                placeholder="Search"
                type="search"
              />
            </div>
          </form>
        </div>
        <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
          <a
            type="button"
            href={"http://"+(enterprise != null ? enterprise.subdomain + "." : "")+ ( TEST_MODE ? "feeback.com:3000" : "justfeedback.co")}
            target="_blank"
            className="inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md  border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
          >
            <span className='mr-4'>Public Board</span>
            <ExternalLinkIcon className="-ml-1 mr-2 h-6 w-6 text-gray-400" aria-hidden="true" />
          </a>

          <Popover className="z-0 relative">
            {({ open }) => (
              <>
                <Popover.Button
                  className={classNames(
                    open ? 'text-gray-900' : 'text-white',
                    'inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm bg-green-800 hover:bg-green-700 focus:outline-none'
                  )}
                >
                  <PlusSmIcon className="h-6 w-6" aria-hidden="true" />
                  <span className='ml-1'>
                      Create Feedback
                    </span>
                </Popover.Button>
                <Popover.Panel className="w-[800px] h-[450px] fixed mx-auto inset-0 inset-y-20 bg-white shadow sm:rounded-lg p-4 mt-6 space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                  <section aria-labelledby="payment-details-heading">
                    <div>
                      <div className="">
                        <div className="bg-white py-6 px-4 sm:p-6">
                          <div>
                            <h2 id="payment-details-heading" className="text-lg leading-6 font-medium text-gray-900">
                              Create a new feedback
                            </h2>
                          </div>

                          <div className="mt-6 grid grid-cols-4 gap-6">
                            <div className="col-span-4 sm:col-span-4">
                              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Feedback title
                              </label>
                              <input
                                value={featureTitle}
                                onChange={(e)=>setFeatureTitle(e.target.value)}
                                type="text"
                                name="title"
                                id="title"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm"
                              />
                            </div>

                            <div className="col-span-4 sm:col-span-4">
                              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Description
                              </label>
                              <textarea
                                value={featureDescription}
                                onChange={(e)=>setFeatureDescription(e.target.value)}
                                name="name"
                                id="name"
                                className="h-32 mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm"
                              >
                                    </textarea>
                            </div>

                          </div>
                        </div>
                        <div className="pt-8 flex justify-end mb-6 mr-4">
                          <Popover.Button
                            type="button"
                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-blue-gray-900 hover:bg-blue-gray-50 focus:outline-none"
                            onClick={() => document.body.click()}
                          >
                            Cancel
                          </Popover.Button>
                          <button
                            onClick={submitFeedback}
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-800 hover:bg-green-700 focus:outline-none"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </section>
                </Popover.Panel>
              </>
            )}
          </Popover>
        </div>
      </div>
    </div>
  </header>
  <div className="lg:hidden">
    <div className="flex items-center justify-between bg-gray-50 border-b border-gray-200 px-4 py-1.5">
      <div>
      </div>
      <div>
        <button
          type="button"
          className="-mr-3 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none"
          onClick={() => props.setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <MenuIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
    </>);
}