import React, { useEffect, useState } from 'react';

import { SketchPicker } from 'react-color';
import serviceClient from "../helpers/ServiceClient";
import { toast } from 'react-toastify';
import ImageCrop from './ImageCrop';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


export default function BrandingSettings() {

    const [brandName, setBrandName] = useState('');
    const [logo, setLogo] = useState(undefined);
    const [favIcon, setFavIcon] = useState(undefined);
    const [brandColor, setBrandColor] = useState("");
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [enterprise, setEnterprise] = useState({});
    const [favIconPreview, setFavIconPreview] = useState(null);
    const [logoPreview, setLogoPreview] = useState(null);


    useEffect(()=>{
      refreshEnterprise();
    }, []);

    const handleColorPick = (color) => {
        console.log("Invoked color is " + color.hex);
        setBrandColor(color.hex);
        // setShowColorPicker(false);
    };

    const handleLogoPick = (e) => {
      setLogo(e.target.files[0]);
      setLogoPreview(undefined);
      // const reader = new FileReader();
      // reader.onload = function (e) {
      //     setLogoPreview(e.target.result);
      // }
      // reader.readAsDataURL(e.target.files[0]);
    };

    const handleFavIconPick = (e) => {
      setFavIcon(e.target.files[0]);
      setFavIconPreview(undefined);
      // var reader = new FileReader();
      //
      // reader.onload = function (e) {
      //     setFavIconPreview(e.target.result);
      // }
      //
      // reader.readAsDataURL(e.target.files[0]);
    };

    const clearLogo = () =>{
      setLogo(undefined);
      setLogoPreview(null);
    }

    const clearFavIcon = () => {
      setFavIcon(undefined);
      setFavIconPreview(null);
    }

    const refreshEnterprise = (callback) => {
      serviceClient.getEnterpriseFromUser({}, (response)=>{
        setEnterprise(response.data);
        if(callback) callback();
      });
    };

    const processLogoCanvas = (canvas) => {
      canvas.toBlob((blob)=>{
          setLogo(new File([blob], "fileName.png", { type: "image/png" }));
        }
      );
      setLogoPreview(canvas.toDataURL("image/png"));
    }

  const processFavIconCanvas = (canvas) => {
    canvas.toBlob((blob)=>{
        setFavIcon(new File([blob], "fileName.png", { type: "image/png" }));
      }
    );
    setFavIconPreview(canvas.toDataURL("image/png"));
  }

    const clear = () => {
      setBrandName("");
      setBrandColor("");
      setLogo(undefined);
      setFavIcon(undefined);
      setLogoPreview(null);
      setFavIconPreview(null);
    }

    const save = () => {
      const formData = new FormData();
      formData.append("enterpriseId", enterprise.id);
      if(logoPreview) formData.append("logoFile", logo);
      if(favIcon) formData.append("faviconFile", favIcon);
      if(brandName) formData.append("name", brandName);
      if(brandColor) formData.append("color", brandColor);
      serviceClient.updateEnterprise(formData, ()=>{
        refreshEnterprise(()=>{
          clear();
        });
        toast("Updated brand settings!!!");
      });
    }

    return (
        <main className="h-screen flex-1 relative z-0 overflow-y-scroll focus:outline-none xl:order-last">
            <div className="flex-1 xl:overflow-y-auto">
                  <div className="max-w-3xl mx-auto py-10 px-4 sm:px-6 lg:py-12 lg:px-8">
                    <h1 className="text-3xl font-extrabold text-blue-gray-900">Branding</h1>

                    <div className="mt-6">
                      <div className="grid grid-cols-1 gap-y-12 sm:grid-cols-6 sm:gap-x-6">

                        <div className="sm:col-span-7">
                          <label htmlFor="first-name" className="block text-sm font-medium text-blue-gray-900">
                            Brand name
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            autoComplete="name"
                            value={brandName ? brandName : enterprise.name}
                            onChange={(e)=>setBrandName(e.target.value)}
                            className="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:outline-none focus:ring-green-900 focus:border-green-900"
                          />
                        </div>

                        <div className="sm:col-span-3">
                          <label htmlFor="photo" className="block text-sm font-medium text-blue-gray-900">
                            Logo
                          </label>
                          <div className="mt-1 flex items-center">
                            <img
                              className="inline-block h-12 w-auto rounded-full"
                              src={logoPreview ? logoPreview : "/api/enterprise/logo/" + enterprise.id}
                              alt=""
                            />
                            <div className="ml-4 flex">
                              <div className="relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none">
                                <label
                                  htmlFor="logo-photo"
                                  className="relative text-sm font-medium text-blue-gray-900 pointer-events-none"
                                >
                                  <span>Change</span>
                                  <span className="sr-only"> logo photo</span>
                                </label>
                                <input
                                  id="logo-photo"
                                  name="logo-photo"
                                  type="file"
                                  onChange={handleLogoPick}
                                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                                />
                              </div>
                              <button
                                type="button"
                                onClick={clearLogo}
                                className={
                                  (logoPreview ? '' : 'hidden ') + 
                                  "ml-3 bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-blue-gray-900 hover:text-blue-gray-700 focus:outline-none focus:border-blue-gray-300"
                                }
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label htmlFor="photo" className="block text-sm font-medium text-blue-gray-900">
                            Favicon
                          </label>
                          <div className="mt-1 flex items-center">
                            <img
                              className="inline-block h-12 w-auto rounded-full"
                              src={favIconPreview ? favIconPreview : "/api/enterprise/favicon/" + enterprise.id}
                              alt=""
                            />
                            <div className="ml-4 flex">
                              <div className="relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none">
                                <label
                                  htmlFor="favicon-photo"
                                  className="relative text-sm font-medium text-blue-gray-900 pointer-events-none"
                                >
                                  <span>Change</span>
                                  <span className="sr-only"> favicon photo</span>
                                </label>
                                <input
                                  id="favicon-photo"
                                  name="favicon-photo"
                                  type="file"
                                  onChange={handleFavIconPick}
                                  // value={favIcon != null ? favIcon.name : "File (.jpg)"}
                                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                                />
                              </div>
                              <button
                                type="button"
                                onClick={clearFavIcon}
                                className={
                                  (favIconPreview ? '' : 'hidden ') + 
                                  "ml-3 bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-blue-gray-900 hover:text-blue-gray-700 focus:outline-none focus:border-blue-gray-300"}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="sm:col-span-1">
                                <label htmlFor="first-name" className="block text-sm font-medium text-blue-gray-900">
                                    Brand Color
                                </label>
                                <div style={{background: brandColor ? brandColor : enterprise.color}} onClick={()=>setShowColorPicker(!showColorPicker)} className='h-12 w-12 rounded-full'>

                                </div>
                                <SketchPicker 
                                    className={classNames(
                                        showColorPicker ? '' : 'hidden',
                                        'absolute'
                                      )}
                                    color={ brandColor ? brandColor : enterprise.color }
                                    onChange ={ handleColorPick }
                                />
                                
                            </div>
                      </div>


                      <div className="pt-8 flex justify-end mb-6 mt-2">
                        <button
                          type="button"
                          onClick={clear}
                          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-blue-gray-900 hover:bg-blue-gray-50"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={save}
                          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-800 hover:bg-green-700"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {logo && !logoPreview && <ImageCrop src={URL.createObjectURL(logo)}
                                                    onImageCrop={(canvas)=>processLogoCanvas(canvas)}
                                                    onCancel={()=>setLogo(undefined)}/>
                }
                {favIcon && !favIconPreview && <ImageCrop src={URL.createObjectURL(favIcon)}
                                                    onImageCrop={(canvas)=>processFavIconCanvas(canvas)}
                                                    onCancel={()=>setFavIcon(undefined)}/>
                }
            </main>
    );
}