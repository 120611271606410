import React, { useState, useEffect } from "react";
import NavigationPage from "./pages/NavigationPage";
import RegisterPage from "./pages/RegisterPage";

import { Redirect, Route, Switch } from "react-router-dom";
import InitialLoginPage from "./pages/InitialLoginPage";
import PublicFeedbacksPage from "./pages/PublicFeedbacksPage";
import LoginPage from "./pages/LoginPage";
import PasswordRecoverPage from "./pages/PasswordRecoverPage";
import SignupProgressPage from "./pages/SignupProgressPage";
import serviceClient from "./helpers/ServiceClient";
import PrivateRoute from "./components/PrivateRoute";
import { EnterpriseContext } from './components/EnterpriseContext';
import { UserContext } from "./components/UserContext";
import { useHistory } from "react-router-dom";
import axios from "axios";
import SingleFeedbackPage from "./pages/SingleFeedbackPage";
import PublicFeedbacksPage2 from "./pages/PublicFeedbacksPage2";
import LandingPage from './pages/LandingPage';



export default function Routes() {

const [authorized, setAuthorized] = useState(null);
const [accountActive, setAccountActive] = useState(null);
const [enterprise, setEnterprise] = useState({});
const [user, setUser] = useState({});
const history = useHistory();

const APP_DOMAIN_NAMES = ["feedback.com", "localhost", "justfeedback.co", "ab12ab.shop", "just-feedback.netlify.app"];

const getUserData = () => {
    //setAuthorized(null);
    console.log("This is invoked");
    serviceClient.getUser((response)=>{
        setUser(response.data);
        setAuthorized(true);
        serviceClient.getEnterpriseFromUser({email: response.data.email}, (response)=>setEnterprise(response.data));
    }, (error)=>{setAuthorized(false);});
};

useEffect(()=>getUserData(), []);
useEffect(()=>setAccountActive(true), []);

const endsWithAnyValidDomainNames = (domainName) => {
    return APP_DOMAIN_NAMES.some(appDomain=>domainName.endsWith("."+appDomain))
}

const postLogin = async ()=> {
    try {
        const response = await axios({
            method: "get",
            url: "/api/session/getUser",
          });
    
        setUser(response.data);
        setAuthorized(true);

        try{
            const response2 = await axios({
                method: "get",
                url: "/api/enterprise/getFromUser",
                params: {email: response.data.email}
            });
        
            setEnterprise(response2.data);
        } catch(err) {
            setEnterprise({});
        }
        
    } catch(err) {
        setUser({});
        setAuthorized(false);
    }
}

const hostName = window.location.host.split(":")[0];
let subdomain = "";
if(endsWithAnyValidDomainNames(hostName) && hostName.split(".")[0] != "www") {
    subdomain = hostName.split(".")[0];
}

if(subdomain) {
    return (
        <UserContext.Provider value={user}>
            <Switch>
            <Route
                exact
                path="/"
                render={() => <Redirect to="/feedbacks" />}
                />

            <Route
                exact
                path="/feedbacks0"
                render={() => <PublicFeedbacksPage subdomain={subdomain} loginCallback={postLogin}/>}
                />
            
            <Route
                exact
                path="/:board"
                render={() => <PublicFeedbacksPage2 subdomain={subdomain} loginCallback={postLogin}/>}
                />
            </Switch>
        </UserContext.Provider>
    );
} else {
    return (
    <EnterpriseContext.Provider value={enterprise} >
        <UserContext.Provider value={user}>
        <Switch>
            <Route
            exact
            path="/"
            render={() => <LandingPage />}
            />

            <Route
                exact
                path="/register"
                render={() => <RegisterPage />}
            />

            <Route
            exact
            path="/login"
            render={() => <LoginPage loginCallback={postLogin} />}
            />

            <Route
            exact
            path="/logout"
            render={() => {localStorage.removeItem("authorization"); return (<Redirect to='/login'/>); }}
            />

            <Route
            exact
            path="/signup-progress"
            render={() => <SignupProgressPage />}
            />

            <Route
            exact
            path="/recover"
            render={() => <PasswordRecoverPage />}
            />

            <Route
            exact
            path="/feedback/:feedbackId"
            render={() => <SingleFeedbackPage />}
            />

            <Route
            exact path="/create-dashboard"
            render={() => <InitialLoginPage />}
            />

            <PrivateRoute authed={authorized} accountActive={accountActive} path="/:page" render={(props) => <NavigationPage {...props}/>} />
        </Switch>
        </UserContext.Provider>
    </EnterpriseContext.Provider>);
    }

}