import React, { useEffect, useRef, useState } from 'react';

import {
  AnnotationIcon,
  BookmarkIcon,
  ClockIcon,
  CheckCircleIcon,
  TrendingUpIcon,
  XCircleIcon
} from '@heroicons/react/outline'
import {
  ChevronRightIcon,
  OfficeBuildingIcon,
} from '@heroicons/react/solid';
import serviceClient from '../helpers/ServiceClient';
import { format } from 'date-fns';
import Pagination from '../components/Pagination';
import DefaultHeader from "../components/DefaultHeader";

const statusStyles = {
  OPEN: 'bg-purple-100 text-purple-700',
  PLANNED: 'bg-indigo-50 text-indigo-700',
  REJECTED: 'bg-red-100 text-red-800',
  IN_PROGRESS: 'bg-yellow-50 text-yellow-800',
  COMPLETED: 'bg-green-100 text-green-800',
  NEW_COMMENT: 'bg-indigo-100 text-green-800',
}

const statusReadable = {
  OPEN: 'Feedback received',
  IN_PROGRESS: 'Is in progress',
  PLANNED: 'Is planned',
  COMPLETED: 'is Completed',
  REJECTED: 'got rejected',
  NEW_COMMENT: 'Received a New Comment',
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


  export default function HomePage(props) {

    const ITEMS_PER_PAGE = 5;
    const [summary, setSummary] = useState({});
    const recentFeedbacks = useRef([]);
    const [user, setUser] = useState({});
    const [slice, setSlice] = useState([]);

    useEffect(()=>{
      refreshSummary();
      refreshUser();
    }, []);

    const handlePageChange = (pageNumber) => {
      setSlice(recentFeedbacks.current.slice(pageNumber*ITEMS_PER_PAGE, (pageNumber+1)*ITEMS_PER_PAGE));
    }

    const refreshUser = () => {
      serviceClient.getUser((response)=>{
        setUser(response.data);
      })
    };

    const refreshSummary = () => {
      serviceClient.getFeedbackSummary({}, (response)=>{
        setSummary(response.data);
      });

      serviceClient.getRecentFeedbacks({}, (response)=>{
        recentFeedbacks.current=response.data;
        setSlice(response.data.slice(0, ITEMS_PER_PAGE));
      })
    };

      return (
        <>
        <DefaultHeader setMobileMenuOpen={props.setMobileMenuOpen} setSideBarOpen={props.setSidebarOpen}/>
        <div className="h-screen overflow-scroll py-12 px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col flex-1">
          <main className="flex-1 pb-8">
            {/* Page header */}
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="mx-auto bg-white shadow">
              <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                <div className="py-6 md:flex md:items-center md:justify-between">
                  <div className="flex-1 min-w-0">
                    {/* Profile */}
                    <div className="flex items-center">
                      <img
                        className="hidden h-16 w-16 rounded-full sm:block"
                        src={"api/user/profilePic/" + user.id }
                        alt=""
                      />
                      <div>
                        <div className="flex items-center">
                          <img
                            className="h-16 w-16 rounded-full sm:hidden"
                            src={"api/user/profilePic/" + user.id }
                            alt=""
                          />
                          <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                            Good morning, {user.fullName ? user.fullName : user.email}
                          </h1>
                        </div>
                        <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                          <dt className="sr-only">Address</dt>
                          <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                            <OfficeBuildingIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            {user.address ? user.address : 'Address not updated'}
                          </dd>
                          <dt className="sr-only">Account status</dt>
                          {user.emailVerified ? (
                              <dd className="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                                <CheckCircleIcon
                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                    aria-hidden="true"
                                />
                                Verified account
                              </dd>
                          ) : (
                              <dd className="mt-3 flex items-center text-sm text-red-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                                <XCircleIcon
                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-500"
                                    aria-hidden="true"
                                />
                                Email not verified. Check your email.
                              </dd>
                          )}

                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>

            <div className="mt-8">
              <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="text-lg leading-6 font-medium text-gray-900">Overview</h2>
                <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                  {/* Card1 */}
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                      <div className="p-5">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <span
                                className={statusStyles['OPEN'] + ' rounded-lg inline-flex p-3 ring-4 ring-white'}
                              >
                              <AnnotationIcon className="h-6 w-6" aria-hidden="true" />
                            </span>
                          </div>
                          <div className="ml-5 w-0 flex-1">
                            <dl>
                              <dt className="text-sm font-medium text-gray-500 truncate">Open</dt>
                              <dd>
                                <div className="text-lg font-medium text-gray-900">{summary.openFeedbacks} Feedbacks</div>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-5 py-3">
                        <div className="text-sm">
                          <a href="feedbacks?status=OPEN" className="font-medium text-cyan-700 hover:text-cyan-900">
                            View all
                          </a>
                        </div>
                      </div>
                    </div>
                     {/* Card2 */}
                     <div className="bg-white overflow-hidden shadow rounded-lg">
                      <div className="p-5">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <span
                                className={statusStyles['PLANNED'] + ' rounded-lg inline-flex p-3 ring-4 ring-white'}
                              >
                              <BookmarkIcon className="h-6 w-6" aria-hidden="true" />
                            </span>
                          </div>
                          <div className="ml-5 w-0 flex-1">
                            <dl>
                              <dt className="text-sm font-medium text-gray-500 truncate">Planned</dt>
                              <dd>
                                <div className="text-lg font-medium text-gray-900">{summary.plannedFeedbacks} Feedbacks</div>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-5 py-3">
                        <div className="text-sm">
                          <a href="feedbacks?status=PLANNED" className="font-medium text-cyan-700 hover:text-cyan-900">
                            View all
                          </a>
                        </div>
                      </div>
                    </div>
                     {/* Card3 */}
                     <div className="bg-white overflow-hidden shadow rounded-lg">
                      <div className="p-5">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <span
                                className={statusStyles['IN_PROGRESS'] + ' rounded-lg inline-flex p-3 ring-4 ring-white'}
                              >
                            <TrendingUpIcon className="h-6 w-6" aria-hidden="true" />
                            </span>
                          </div>
                          <div className="ml-5 w-0 flex-1">
                            <dl>
                              <dt className="text-sm font-medium text-gray-500 truncate">In Progress</dt>
                              <dd>
                                <div className="text-lg font-medium text-gray-900">{summary.inProgressFeedbacks} Feedbacks</div>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-5 py-3">
                        <div className="text-sm">
                          <a href="feedbacks?status=IN_PROGRESS" className="font-medium text-cyan-700 hover:text-cyan-900">
                            View all
                          </a>
                        </div>
                      </div>
                    </div>
                     {/* Card4 */}
                     <div className="bg-white overflow-hidden shadow rounded-lg">
                      <div className="p-5">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <span
                              className={statusStyles['COMPLETED'] + ' rounded-lg inline-flex p-3 ring-4 ring-white'}
                            >
                            <CheckCircleIcon className="h-6 w-6" aria-hidden="true" />
                            </span>
                          </div>
                          <div className="ml-5 w-0 flex-1">
                            <dl>
                              <dt className="text-sm font-medium text-gray-500 truncate">Completed</dt>
                              <dd>
                                <div className="text-lg font-medium text-gray-900">{summary.completedFeedbacks} Feedbacks</div>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-5 py-3">
                        <div className="text-sm">
                          <a href="feedbacks?status=COMPLETED" className="font-medium text-cyan-700 hover:text-cyan-900">
                            View all
                          </a>
                        </div>
                      </div>
                    </div>
                </div>
              </div>

              <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
                Recent activity
              </h2>

              {/* Activity list (smallest breakpoint only) */}
              <div className="shadow sm:hidden">
                <ul role="list" className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                  {slice.map((feedback) => (
                    <li key={feedback.id+feedback.status}>
                      <a href={'feedback/' + feedback.id} className="block px-4 py-4 bg-white hover:bg-gray-50">
                        <span className="flex items-center space-x-4">
                          <span className="flex-1 flex space-x-2 truncate">
                            <AnnotationIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                            <span className="flex flex-col text-gray-500 text-sm truncate">
                              <span className="truncate">{feedback.title}</span>
                              <span>
                                <span className="text-gray-900 font-medium">{feedback.status}</span>
                              </span>
                              <time dateTime={feedback.updateOn}>{format(new Date(feedback.updatedOn), "dd MMM yyyy, hh:mm a")}</time>
                            </span>
                          </span>
                          <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                      </a>
                    </li>
                  ))}
                </ul>

                <nav
                  className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
                  aria-label="Pagination"
                >
                  <div className="flex-1 flex justify-between">
                    <a
                      href="#"
                      className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                    >
                      Previous
                    </a>
                    <a
                      href="#"
                      className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                    >
                      Next
                    </a>
                  </div>
                </nav>
              </div>

              {/* Activity table (small breakpoint and up) */}
              <div className="hidden sm:block">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="flex flex-col mt-2">
                    <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                          <tr>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Feedback
                            </th>
                            <th className="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block">
                              Activity
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Date
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {slice.map((feedback) => (
                            <tr key={feedback.id+feedback.status} className="bg-white">
                              <td className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                <div className="flex">
                                  <a target="_blank" href={'feedback/'+feedback.id} className="group inline-flex space-x-2 truncate text-sm">
                                    <AnnotationIcon
                                      className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                      aria-hidden="true"
                                    />
                                    <p className="text-gray-500 truncate group-hover:text-gray-900">
                                      {feedback.title}
                                    </p>
                                  </a>
                                </div>
                              </td>
                              <td className="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block">
                                <span
                                  className={classNames(
                                    statusStyles[feedback.status],
                                    'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize'
                                  )}
                                >
                                  {statusReadable[feedback.status]}
                                </span>
                              </td>
                              <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                <time dateTime={feedback.updateOn}>{format(new Date(feedback.updatedOn), "dd MMM yyyy, hh:mm a")}</time>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Pagination totalItems={recentFeedbacks.current.length} itemsPerPage={ITEMS_PER_PAGE} onPageChange={handlePageChange} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          </div>
      </div>
          </>
      );
  }