import React, { useContext, useEffect, useState } from 'react';
import { format } from 'date-fns';
import Expand from 'react-expand-animated';
import serviceClient from "../helpers/ServiceClient";

import {
    XCircleIcon,
    ChevronDownIcon,
    ChevronUpIcon
} from '@heroicons/react/outline';
import { UserContext } from './UserContext';

export default function PublicFeedbackItem(props) {

    const [open, setOpen] = useState(false);
    const [comment, setComment] = useState("");
    const [feedback, setFeedback] = useState(null);

    const userContext = useContext(UserContext);

    const toggle = () => {
        setOpen(!open);
    };

    const submitComment = () => {
        if(userContext && userContext.email && userContext.fullName) {
            serviceClient.comment({
                feedbackId: feedback.id,
                userId: userContext.id,
                content: comment,
            }, ()=>{setComment(""); refreshFeedback()})
        } else {
            props.loginPopup();
        }
    };

    useEffect(()=>{
        refreshFeedback();
    }, [props.feedbackId, userContext]);

    const refreshFeedback = () => {
        serviceClient.getFeedbackDetails({feedbackId: props.feedbackId}, (response)=>{setFeedback(response.data)});
    };

    const vote = () => {
        if(userContext && userContext.email && userContext.fullName) {
            serviceClient.vote({
                feedbackId: feedback.id,
                userId: userContext.id
            }, ()=>{refreshFeedback()});
        } else {
            props.loginPopup();
        }
    }

    return (
        feedback && 
        <div className="card card-wrap">
            <div className="entry-number cursor-pointer">
                <div className="inner-entry" onClick={vote}>
                    <span className="ico"></span>  
                    {feedback.votes}
                </div>
            </div>
            <div className="entry-content">

                <a href="#">
                <div className='w-[450px] h-8' onClick={toggle}>
                    <h3 className="float-left title">{feedback.title}</h3>
                    <button className='float-right w-4 h-4'>{open ? <ChevronUpIcon /> : <ChevronDownIcon />}</button>
                </div>
                <div className="entry-meta" onClick={toggle}>
                    <span className="status development">{feedback.status}</span>
                </div>
                <p className={open ? 'w-[450px] text-justify' : 'truncate w-[450px]'}>{feedback.description}</p>
                <Expand open={open}>
                        <div className="mb-4 mt-4">
                              <label for="featured_title" className="form-label">Add a comment</label>
                              <textarea  
                                  value={comment} 
                                  onChange={(e)=>{setComment(e.target.value)}}
                                  className="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:outline-none focus:ring-green-900 focus:border-green-900" row="3">
                              </textarea> 
                        </div>
                        <button onClick={submitComment} className="btn btn-submit">COMMENT</button>
                        <div className='overflow-scroll-y mt-4'>
                            {feedback.comments.map((comment, idx)=>(
                                <div key={idx} className='my-2 p-2 border-2 border-gray-100 bg-gray-50'>
                                    <div className='h-8'>
                                        <h6 className='text-green-500 float-left text-sm'>{comment.fullName}</h6>
                                        <span className='float-right text-xs'>{format(new Date(comment.createdOn), "dd MMM yyyy, hh:mm a")}</span>
                                    </div>
                                    <p className='text-zinc-700 text-sm'>{comment.content}</p>
                                </div>
                            ))}
                        </div>
                </Expand>

                <ul className="entry-foot">
                <li className="name">{feedback.fullName}</li>
                <li className="date">{format(new Date(feedback.createdOn), "MMM dd, yyyy")}</li>
                <li className="comment"><i className="fa fa-comments"></i>{feedback.comments.length} comments</li>
                </ul>
                </a>
            </div>
        </div>
    );
}