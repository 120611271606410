import React from 'react';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


  export default function InitialLoginPage() {
      return (
        <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src="/jf-icon.svg"
              alt="Workflow"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Create your first board</h2>
          </div>
          <form className="mt-6 space-y-8 divide-y divide-y-blue-gray-200" action="/home" method="GET">
            <div className='grid grid-cols-1 gap-y-6 sm:grid-cols-1 sm:gap-x-6'>
              <div className='sm:col-span-1'>
                <label htmlFor="email-address" className="block text-sm font-medium text-blue-gray-900">
                  Name of your board
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="text"
                  autoComplete="email"
                  required
                  className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-900 focus:border-green-900 focus:z-10 sm:text-sm"
                  placeholder="eg: Feature Requests"
                />
              </div>
              
              <div  className='sm:col-span-1'>
                <label htmlFor="username" className="block text-sm font-medium text-blue-gray-900">
                  URL
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-blue-gray-50 text-gray-500 sm:text-sm">
                    justfeedback.co/
                  </span>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    autoComplete="username"
                    placeholder="eg: feature-requests"
                    className="flex-1 block w-full min-w-0 border-gray-300 rounded-none rounded-r-md text-gray-900 sm:text-sm focus:outline-none focus:ring-green-900 focus:border-green-900"
                  />
                </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-800 hover:bg-green-700 focus:outline-none focus:ring-green-900 focus:border-green-900"
              >
                {/* <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-green-500 group-hover:text-green-400" aria-hidden="true" />
                </span> */}
                Create your board
              </button>
            </div>
            </div>
          </form>
        </div>
      </div>
      );
  }