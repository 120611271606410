import React from 'react';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


  export default function SignupProgressPage() {

    const sendVerificationEmail = () => {
        alert("Verification email sent. Please check our inbox.")
    };

      return (
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-2xl">
          <img
            className="mx-auto h-12 w-auto"
            src="/jf-icon.svg"
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Wait for the process to complete to start using your subdomain</h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-2xl">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className>
                <h4 className="sr-only">Status</h4>
                <p className="text-sm font-medium text-gray-900">Settingup your account</p>
                <div className="mt-6" aria-hidden="true">
                    <div className="bg-gray-200 rounded-full overflow-hidden">
                        <div className="h-2 bg-indigo-600 rounded-full" style={{ width: '37.5%' }} />
                    </div>
                    <div className="hidden sm:grid grid-cols-4 text-sm font-medium text-gray-600 mt-6">
                        <div className="text-green-600">Sign up</div>
                        <div className="text-center text-yellow-600">
                            <div>Verify Email</div>
                            <a onClick={sendVerificationEmail} className='text-indigo-600 underline cursor-pointer'>Resend verification email</a>
                        </div>
                        <div className="text-center">Create subdomain</div>
                        <div className="text-right">Done</div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      );
  }