import React from 'react';

import {
    XCircleIcon
} from '@heroicons/react/outline';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


export default function ErrorBanner(props) {

    return (
        <div>
        {
            props.msg && (
              <div className='mt-2'>
                <div className="flex sm:mx-auto sm:w-full sm:max-w-md border bg-red-100 border-rose-600">
                <div className="flex-shrink-0">
                  <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="w-full text-center text-sm text-rose-600">
                  {props.msg}
                </div>
              </div>
              </div>
            )
          }
        </div>
    );
}