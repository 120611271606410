import React, { useContext, useEffect, useState } from 'react';
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { AnnotationIcon, BookmarkIcon, CheckCircleIcon, CheckIcon, ExclamationCircleIcon } from '@heroicons/react/outline'
import { useParams } from "react-router-dom";
import serviceClient from "../helpers/ServiceClient";
import { UserContext } from '../components/UserContext';
import { toast } from 'react-toastify';
import colorString from 'color-string';


import { Menu, Popover } from '@headlessui/react'
import {
  SearchIcon,
} from '@heroicons/react/solid'
import { BellIcon, FireIcon, HomeIcon, MenuIcon, TrendingUpIcon, UserGroupIcon, XIcon, ThumbUpIcon } from '@heroicons/react/outline'
import PublicFeedbackItem2 from '../components/PublicFeedbackItem2';

const user = {
  name: 'Chelsea Hagon',
  email: 'chelseahagon@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}

const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
]

const tabs = [
  { name: 'Recent', href: '#', sortBy: "Date" },
  { name: 'Most Liked', href: '#', sortBy: "Votes"},
  { name: 'Most Commented', href: '#', sortBy: "Comments"},
]

// import 'bootstrap/dist/css/bootstrap.min.css';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


  export default function PublicFeedbacksPage2(props) {

    const [theme, setTheme] = useState({
      baseColor: '#555555',
      lighter1: '#808080',
      lighter2: '#b6b6b6',
      darker1: '#4c4c4c',
      darker2: '#343434',
    });
    const [postButtonColor, setPostButtonColor] = useState(theme.darker2);
    const [loginLogoutColor, setLoginLogoutColor] = useState(theme.baseColor);

    const [open, setOpen] = useState(false);
    const cancelButtonRef = useRef(null);
    const [feedbackIds, setFeedbackIds] = useState([]);
    const [enterprise, setEnterprise] = useState(undefined);
    const { board } = useParams();

    // New feedback related states
    const [featureTitle, setFeatureTitle] = useState('');
    const [featureDescription, setFeatureDescription] = useState('');
    const [requesterName, setRequesterName] = useState('');
    const [requesterEmail, setRequesterEmail] = useState('');

    // Filtering related states
    const [category, setCategory] = useState("ALL");
    const [sortBy, setSortBy] = useState("Date");
    const [datePosted, setDatePosted] = useState("ALL");

    // Pagination related states
    const ITEMS_PER_PAGE = 10;
    const [allItem, setAllItems] = useState([]);
    const [pageCount, setPageCount] = useState(2);
    const [itemOffset, setItemOffset] = useState(0);

    const userContext = useContext(UserContext);

    const navigation = [
      { name: 'All', onClick: ()=>{setCategory("ALL")}, icon: HomeIcon, current: ()=>(category == "ALL") },
      { name: 'Open', onClick: ()=>{setCategory("OPEN")}, icon: AnnotationIcon, current: ()=>(category == "OPEN") },
      { name: 'Planned', onClick: ()=>{setCategory("PLANNED")}, icon: BookmarkIcon, current: ()=>(category == "PLANNED") },
      { name: 'In Progress', onClick: ()=>{setCategory("IN_PROGRESS")}, icon: TrendingUpIcon, current: ()=>(category == "IN_PROGRESS") },
      { name: 'Completed', onClick: ()=>{setCategory("COMPLETED")}, icon: CheckCircleIcon, current: ()=>(category == "COMPLETED") },
      { name: 'Rejected', onClick: ()=>{setCategory("REJECTED")}, icon: ExclamationCircleIcon, current: ()=>(category == "REJECTED") },
    ]

    const dateFilters = [
      { name: 'Any time', onClick: ()=>{setDatePosted("ALL");}, current:()=>(datePosted == "ALL")},
      { name: '2023', onClick: ()=>{setDatePosted("2023");}, current:()=>(datePosted == "2023")},
      { name: '2022', onClick: ()=>{setDatePosted("2022");}, current:()=>(datePosted == "2022")},
      { name: '2021', onClick: ()=>{setDatePosted("2021");}, current:()=>(datePosted == "2021")},
      { name: '2020', onClick: ()=>{setDatePosted("2020");}, current:()=>(datePosted == "2020")},
      // { name: 'Custom Date Filter', href: '#' }
    ]

    const handlePageClick = (event) => {
      console.log("Event selected is " + event.selected);
      const newOffset = (event.selected * ITEMS_PER_PAGE) % allItem.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
      setFeedbackIds(allItem.slice(newOffset, newOffset + ITEMS_PER_PAGE));
    };


    useEffect(()=>{getFeedbacks();}, [category, sortBy, datePosted, userContext]);
    useEffect(()=>{getEnterprise();}, [props.subdomain]);

    const submitFeedback = () => {
      if(userContext && userContext.email && userContext.fullName) {
        serviceClient.saveFeedback({
          title: featureTitle,
          description: featureDescription,
          fullName: requesterName,
          userEmail: requesterEmail,
          subdomain: props.subdomain,
          board: board,
        },
        ()=>{
          toast("Submitted the feedback");
          getFeedbacks();
          setFeatureTitle("");
          setRequesterEmail("");
          setRequesterName("");
          setFeatureDescription("");
        },
        (err)=>{
          toast.error(err.message);
        });
      } else {
        setOpen(true);
      }

    }

    const getFeedbacks = () => {
      let startTime = null;
      let endTime = null;

      if(datePosted != "ALL") {
        startTime = new Date(datePosted + '-01-01T00:00:00').getTime();
        endTime = new Date(datePosted + '-12-31T23:59:59').getTime();
      }

      serviceClient.getAllFeedbackIds({
        subdomain: props.subdomain,
        status: category,
        startTime: startTime,
        endTime: endTime,
        orderBy: sortBy
      },
        (response)=>{
          setAllItems(response.data);
          setFeedbackIds(response.data.slice(itemOffset, itemOffset+ITEMS_PER_PAGE));
          setPageCount(response.data.length / ITEMS_PER_PAGE);
        })
    };

    const getEnterprise = () => {
      serviceClient.getEnterprise(props.subdomain,
        (response)=>{
          setEnterprise(response.data);
          let link = document.querySelector("link[rel~='icon']");
          if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
          }
          link.href = "/api/enterprise/favicon/" + response.data.id;

          const baseColor = response.data.color;
          const [r, g, b, a] = colorString.get.rgb(baseColor);
          const lighter1 = colorString.to.hex([Math.min(255, r * 12/10), Math.min(255, g*12/10), Math.min(255, b*12/10)]);
          const lighter2 = colorString.to.hex([Math.min(255, r * 14/10), Math.min(255, g*14/10), Math.min(255, b*14/10)]);
          const darker1 = colorString.to.hex([r * 8/10, g*8/10, b*8/10]);
          const darker2 = colorString.to.hex([r * 6/10, g*6/10, b*6/10]);
          setTheme({
            baseColor,
            lighter1,
            lighter2,
            darker1,
            darker2,
          });
          setLoginLogoutColor(baseColor);
          setPostButtonColor(darker2);
        });
    };

    const login = () => {
      serviceClient.authenticateCustomer({username: requesterEmail, fullName: requesterName},
        ()=>{props.loginCallback(); setOpen(false);});
    }

    const handleCategory = (e) => {
      setCategory(e.currentTarget.value);
    };

    const handleSortBy = (e) => {
      setSortBy(e.currentTarget.value);
    };

    const handleDatePosted = (e) => {
      setDatePosted(e.currentTarget.value);
    };


    const loginModal = () => {
      return (
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <h4 className='mb-4'>Enter your details.</h4>
                  <div className="space-y-6" action="#" method="POST">
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Email address
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          value={requesterEmail} 
                          onChange={(e)=>{setRequesterEmail(e.target.value)}}
                          type="email"
                          autoComplete="email"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="fullname" className="block text-sm font-medium text-gray-700">
                        Your name
                      </label>
                      <div className="mt-1">
                        <input
                          id="fullname"
                          name="fullname"
                          value={requesterName} 
                          onChange={(e)=>{setRequesterName(e.target.value)}}
                          type="text"
                          autoComplete="name"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                      <button
                        type="button"
                        className="sm:col-start-2 sm:text-sm w-full inline-flex justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-rose-600 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                        onClick={login}
                      >
                        Continue
                      </button>

                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      );
    }

    const renderLoginLogout = () => {
      if(userContext && userContext.fullName) {
        return (<div>
          {/* <div className='float-left mt-2 mr-2'>Hi {userContext.fullName}</div> */}
          <button style={{backgroundColor: loginLogoutColor}} className='ml-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500'
                  onMouseOver={()=>setLoginLogoutColor(theme.lighter1)}
                  onMouseLeave={()=>setLoginLogoutColor(theme.baseColor)}
                  onClick={()=>{localStorage.removeItem("authorization"); props.loginCallback();}}>Logout</button>
          </div>);
      } else {
        return (<button style={{backgroundColor: loginLogoutColor}} className='ml-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500'
                        onMouseOver={()=>setLoginLogoutColor(theme.lighter1)}
                        onMouseLeave={()=>setLoginLogoutColor(theme.baseColor)}
                        onClick={()=>{setOpen(true);}}>Login</button>);
      }
      
    }

      return (
        <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
      <div className="min-h-full">
        {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
        <Popover
          as="header"
          className={({ open }) =>
            classNames(
              open ? 'fixed inset-0 z-40 overflow-y-auto' : '',
              'bg-white shadow-sm lg:static lg:overflow-y-visible'
            )
          }
        >
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="relative flex justify-between xl:grid xl:grid-cols-12 lg:gap-8">
                  <div className="flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-2">
                    <div className="flex-shrink-0 flex items-center">
                      <a href="#">
                        <img
                          className="block h-8 w-auto"
                          src={enterprise?.id ? "/api/enterprise/logo/" + enterprise.id : ""}
                          alt="Workflow"
                        />
                      </a>
                      <span className="ml-2 text-gray-600">{enterprise?.name}</span>
                    </div>
                  </div>
                  <div className="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6">
                    <div className="flex items-center px-6 py-4 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
                      <div className="w-full">
                        <label htmlFor="search" className="sr-only">
                          Search
                        </label>
                        <div className="relative">
                          <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </div>
                          <input
                            id="search"
                            name="search"
                            className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                            placeholder="Search"
                            type="search"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center md:absolute md:right-0 md:inset-y-0 lg:hidden">
                    {/* Mobile menu button */}
                    <Popover.Button className="-mx-2 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-rose-500">
                      <span className="sr-only">Open menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Popover.Button>
                  </div>
                  <div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4">
                    <a
                      href="#"
                      className="ml-5 flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </a>

                    {/* Profile dropdown */}
                    <Menu as="div" className="flex-shrink-0 relative ml-5">
                      <div>
                        <Menu.Button className="bg-white rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500">
                          <span className="sr-only">Open user menu</span>
                          <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  onClick={item.onClick}
                                  style={{backgroundColor: (active ? theme.lighter2 : '')}}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block py-2 px-4 text-sm text-gray-700'
                                  )}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>

                    
                      {renderLoginLogout()}
                  </div>
                </div>
              </div>

              <Popover.Panel as="nav" className="lg:hidden" aria-label="Global">
                <div className="max-w-3xl mx-auto px-2 pt-2 pb-3 space-y-1 sm:px-4">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      onClick={item.onClick}
                      aria-current={item.current() ? 'page' : undefined}
                      className={classNames(
                        item.current() ? 'bg-gray-100 text-gray-900' : 'hover:bg-gray-50',
                        'block rounded-md py-2 px-3 text-base font-medium'
                      )}
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="border-t border-gray-200 pt-4">
                  <div className="max-w-3xl mx-auto px-4 flex items-center sm:px-6">
                    <div className="flex-shrink-0">
                      <span className={'bg-purple-100 text-purple-700 rounded-lg inline-flex p-3 ring-4 ring-white'}>
                          <BookmarkIcon className="h-6 w-6" aria-hidden="true" />
                      </span>
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-gray-800">{user.name}</div>
                      <div className="text-sm font-medium text-gray-500">{user.email}</div>
                    </div>
                    <button
                      type="button"
                      className="ml-auto flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-3 max-w-3xl mx-auto px-2 space-y-1 sm:px-4">
                    {userNavigation.map((item) => (
                      <a
                        key={item.name}
                        onClick={item.onClick}
                        className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>

                <div className="mt-6 max-w-3xl mx-auto px-4 sm:px-6">
                  <a
                    href="#"
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-rose-600 hover:bg-rose-700"
                  >
                    New Post
                  </a>

                  <div className="mt-6 flex justify-center">
                    <a href="#" className="text-base font-medium text-gray-900 hover:underline">
                      Go Premium
                    </a>
                  </div>
                </div>
              </Popover.Panel>
            </>
          )}
        </Popover>

        <div className="py-10 pb-20 h-screen overflow-scroll">
          <div className="max-w-3xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="hidden lg:block lg:col-span-3 xl:col-span-2">
              <nav aria-label="Sidebar" className="sticky top-4 divide-y divide-gray-300">
                <div className="pb-8 space-y-1">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      onClick={item.onClick}
                      style={{backgroundColor: (item.current() ? theme.lighter2 : '')}}
                      className={classNames(
                        item.current() ? 'bg-gray-200 text-gray-900' : 'text-gray-600 hover:bg-gray-50',
                        'group flex items-center px-3 py-2 text-sm font-medium rounded-md cursor-pointer'
                      )}
                      aria-current={item.current() ? 'page' : undefined}
                    >
                      <item.icon
                        className={classNames(
                          item.current() ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                          'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                      <span className="truncate">{item.name}</span>
                    </a>
                  ))}
                </div>
                <div className="pt-10">
                  <p
                    className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider"
                    id="communities-headline"
                  >
                    Date filters
                  </p>
                  <div className="mt-3 space-y-2" aria-labelledby="communities-headline">
                    {dateFilters.map((filter) => (
                      <a
                        key={filter.name}
                        onClick={filter.onClick}
                        style={{backgroundColor: (filter.current() ? theme.lighter2 : '')}}
                        className={classNames(
                          filter.current() ? 'bg-gray-200 text-gray-900' : 'text-gray-600 hover:bg-gray-50',
                          "group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md cursor-pointer")}
                      >
                        {filter.name}
                      </a>
                    ))}
                  </div>
                </div>
              </nav>
            </div>
            <main className="lg:col-span-9 xl:col-span-6">
              <div className="px-4 sm:px-0">
                <div className="sm:hidden">
                  <label htmlFor="question-tabs" className="sr-only">
                    Select a tab
                  </label>
                  <select
                    id="question-tabs"
                    className="block w-full rounded-md border-gray-300 text-base font-medium text-gray-900 shadow-sm focus:border-rose-500 focus:ring-rose-500"
                    defaultValue={tabs.find((tab) => tab.sortBy === sortBy).name}
                  >
                    {tabs.map((tab) => (
                      <option key={tab.name}>{tab.name}</option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <nav className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
                    {tabs.map((tab, tabIdx) => (
                      <a
                        key={tab.name}
                        onClick={()=>setSortBy(tab.sortBy)}
                        aria-current={tab.current ? 'page' : undefined}
                        className={classNames(
                          tab.current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                          tabIdx === 0 ? 'rounded-l-lg' : '',
                          tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                          'cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-6 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
                        )}
                      >
                        <span>{tab.name}</span>
                        <span
                          aria-hidden="true"
                          style={{backgroundColor: (tab.sortBy === sortBy ? theme.darker1 : '#ffffffff')}}
                          className={classNames(
                            'absolute inset-x-0 bottom-0 h-0.5'
                          )}
                        />
                      </a>
                    ))}
                  </nav>
                </div>
              </div>
              <div className="mt-4">
                <h1 className="sr-only">Recent questions</h1>
                <ul role="list" className="space-y-4">
                  {feedbackIds.map((feedbackId) => (
                    <PublicFeedbackItem2 feedbackId={feedbackId} loginPopup={()=>{setOpen(true);}} theme={theme}/>
                  ))}
                </ul>
              </div>
            </main>
            <aside className="hidden xl:block xl:col-span-4">
              <div className="sticky top-4 space-y-4">
                <section aria-labelledby="who-to-follow-heading">

                <div className="bg-white">
                  <div className="relative">
                    <div aria-hidden="true" className="hidden sm:block">
                      <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl" />
                      <svg className="absolute  -top-6 left-6 -ml-3" width={404} height={392} fill="none" viewBox="0 0 404 392">
                        <defs>
                          <pattern
                            id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                          >
                            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                          </pattern>
                        </defs>
                        <rect width={404} height={392} fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)" />
                      </svg>
                    </div>
                    <div className="mx-auto max-w-md sm:max-w-3xl lg:max-w-7xl">
                      <div style={{backgroundColor: theme.baseColor}} className="relative rounded-2xl px-2 py-2 overflow-hidden shadow-xl sm:px-6 sm:py-8">
                        <div aria-hidden="true" className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
                          <svg
                            className="absolute inset-0 h-full w-full"
                            preserveAspectRatio="xMidYMid slice"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 1463 360"
                          >
                            <path
                              style={{color: theme.darker1}}
                              className="text-opacity-40"
                              fill="currentColor"
                              d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                            />
                            <path
                              style={{color: theme.lighter2}}
                              className="text-opacity-40"
                              fill="currentColor"
                              d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                            />
                          </svg>
                        </div>
                        <div className="relative">
                        <h2 id="trending-heading" class="text-base font-medium text-white">Send a Feedback</h2>
                          <div className="mt-6 flow-root">
                            <div className="">
                            <div className="space-y-6">
                              <div>
                                <label htmlFor="email" className="block text-sm font-medium text-white">
                                  Title
                                </label>
                                <div className="mt-1">
                                  <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    value={featureTitle}
                                    onChange={(e)=>{setFeatureTitle(e.target.value)}}
                                    required
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none  focus:ring-[#78021c] focus:border-[#78021c] sm:text-sm"
                                  />
                                </div>
                              </div>

                              <div>
                                <label htmlFor="description" className="block text-sm font-medium text-white">
                                  Description
                                </label>
                                <div className="mt-1">
                                  <textarea
                                    id="description"
                                    name="description"
                                    value={featureDescription}
                                    onChange={(e)=>{setFeatureDescription(e.target.value)}}
                                    autoComplete="description"
                                    required
                                    className="appearance-none h-32 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#78021c] focus:border-[#78021c] sm:text-sm"
                                  />
                                </div>
                              </div>


                              <div>
                                <button
                                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none  focus:ring-[#78021c] focus:border-[#78021c]"
                                  style={{backgroundColor: postButtonColor}}
                                  onMouseOver={()=>setPostButtonColor(theme.lighter1)}
                                  onMouseLeave={()=>setPostButtonColor(theme.darker2)}
                                  onClick={submitFeedback}
                                >
                                  Post Feedback
                                </button>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>      
                </div>
              </div>
              </section>
              </div>
            </aside>
          </div>
        </div>
        {loginModal()}
      </div>
    </>
      );
  }