import React, { useContext, useEffect } from 'react';
import {
  SortAscendingIcon,
  ChevronRightIcon,
  LockOpenIcon,
  ChatAltIcon,
  CalendarIcon,
  GlobeIcon,
  ThumbUpIcon,
  ChevronDownIcon,
  UserCircleIcon as UserCircleIconSolid,
} from '@heroicons/react/outline';
import { SearchIcon } from '@heroicons/react/solid';


import {Fragment, useState } from 'react';
import { Dialog, Transition, Menu } from '@headlessui/react';
import serviceClient from '../helpers/ServiceClient';
import { EnterpriseContext } from '../components/EnterpriseContext';
import DefaultHeader from "../components/DefaultHeader";

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


  export default function UsersPage(props) {
    const enterprise = useContext(EnterpriseContext);
    const [currentUser, setCurrentUser] = useState(null);
    const [directory, setDirectory] = useState({});
    const [feedbacks, setFeedbacks] = useState([]);

    useEffect(()=>{
      getDirectory();
    }, [enterprise]);

    useEffect(()=>{getFeedback();}, [currentUser]);

    const getDirectory = () => {
      if(enterprise) {
        serviceClient.getFeedbackCustomers({subdomain: enterprise.subdomain}, (response) => {
          const customerList = response.data;
          let dir = {};
          customerList.forEach(customer=>{
            console.log(customer);
            const firstLetter = customer.fullName[0].toUpperCase();
            if(dir[firstLetter] == null) {
              dir[firstLetter] = [customer];
            } else {
              dir[firstLetter] = dir[firstLetter].concat(customer);
            }
          });

          console.log(dir);
          setDirectory(dir);

        });
      }
    };

    const getFeedback = () => {
      if(currentUser) {
        serviceClient.getFeedbacksByUserId({userId: currentUser.id}, (response)=>{
          setFeedbacks(response.data);
        });
      }
    }

      return (
        <>
          <DefaultHeader setMobileMenuOpen={props.setMobileMenuOpen} setSideBarOpen={props.setSidebarOpen}/>
        <div className="flex-1 relative z-0 flex overflow-hidden">
            <main className="flex-1">
            <div className="py-8 xl:py-10">
              <div className="px-4 sm:px-6 lg:px-8 xl:grid xl:grid-cols-3">
                <div className="xl:col-span-2 xl:pr-8 xl:border-r xl:border-gray-200 h-screen overflow-y-scroll">
                  <div>
                    <div>
                      <div className="md:flex md:items-center md:justify-between md:space-x-4 xl:border-b xl:pb-6">
                        {currentUser && feedbacks &&
                          <div>
                          <h1 className="text-2xl font-bold text-gray-900">{currentUser.fullName}</h1>
                          <p className="mt-2 text-sm text-gray-500">
                            <a href="#" className="font-medium text-gray-900">
                            {currentUser.fullName}
                            </a>{' '}
                            opened{' '}
                            <a href="#" className="font-medium text-gray-900">
                              {feedbacks.length} different issues
                            </a>
                          </p>
                        </div>
                        }
                      </div>
                      <aside className="mt-8 xl:hidden">
                        <h2 className="sr-only mb-6">Leslie Abbott</h2>
                        <div className="space-y-5 mt-6">
                          <div className="flex items-center space-x-2">
                            <LockOpenIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
                            <span className="text-green-700 text-sm font-medium">Active User</span>
                          </div>
                          <div className="flex items-center space-x-2">
                            <ChatAltIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            <span className="text-gray-900 text-sm font-medium">4 comments</span>
                          </div>
                          <div className="flex items-center space-x-2">
                            <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            <span className="text-gray-900 text-sm font-medium">
                              Member since <time dateTime="2020-12-02">Dec 2, 2020</time>
                            </span>
                          </div>
                        </div>
                      </aside>
                      <div className="py-3 xl:pt-6 xl:pb-0">
                        {/* Feature Requests list Comes here */}

                        <nav
                          aria-label="Sections"
                          className="hidden flex-shrink-0 w-500 bg-white border-r border-blue-gray-200 xl:flex xl:flex-col"
                        >
                      <div className="bg-white lg:min-w-0 lg:flex-1">
                        <div className="pl-4 pr-6 pt-4 pb-4 border-b border-t border-gray-200 sm:pl-6 lg:pl-8 xl:pl-6 xl:pt-6 xl:border-t-0">
                          <div className="flex items-center">
                            <h1 className="flex-1 text-lg font-medium">Feedbacks</h1>

                            <Menu as="div" className="relative ml-3">
                              <Menu.Button className="w-full bg-white border border-gray-300 rounded-md shadow-sm px-4 py-2 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:border-green-900">
                                <SortAscendingIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                Filter by Date
                                <ChevronDownIcon className="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                              </Menu.Button>
                              <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="#"
                                        className={classNames(
                                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                          'block px-4 py-2 text-sm'
                                        )}
                                      >
                                        Name
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="#"
                                        className={classNames(
                                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                          'block px-4 py-2 text-sm'
                                        )}
                                      >
                                        Last week
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="#"
                                        className={classNames(
                                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                          'block px-4 py-2 text-sm'
                                        )}
                                      >
                                        Last Month
                                      </a>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Menu>

                            <Menu as="div" className="relative ml-3">
                              <Menu.Button className="w-full bg-white border border-gray-300 rounded-md shadow-sm px-4 py-2 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:border-green-900">
                                <SortAscendingIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                Filter by status
                                <ChevronDownIcon className="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                              </Menu.Button>
                              <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="#"
                                        className={classNames(
                                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                          'block px-4 py-2 text-sm'
                                        )}
                                      >
                                        Open
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="#"
                                        className={classNames(
                                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                          'block px-4 py-2 text-sm'
                                        )}
                                      >
                                        In Progress
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="#"
                                        className={classNames(
                                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                          'block px-4 py-2 text-sm'
                                        )}
                                      >
                                        Closed
                                      </a>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Menu>
                          </div>
                        </div>
                        <ul role="list" className="relative z-0 divide-y divide-gray-200 border-b border-gray-200">
                          {feedbacks.map((project) => (
                            <li
                              key={project.id}
                              className="relative pl-4 pr-6 py-5 hover:bg-gray-50 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6"
                            >
                              <div className="flex items-center justify-between space-x-4">
                                {/* Repo name and link */}
                                <div className="min-w-0 space-y-3">
                                  <div className="flex items-center space-x-3">
                                    <span
                                      className={classNames(
                                        project.active ? 'bg-green-100' : 'bg-gray-100',
                                        'h-4 w-4 rounded-full flex items-center justify-center'
                                      )}
                                      aria-hidden="true"
                                    >
                                      <span
                                        className={classNames(
                                          project.active ? 'bg-green-400' : 'bg-gray-400',
                                          'h-2 w-2 rounded-full'
                                        )}
                                      />
                                    </span>

                                    <span className="block">
                                      <h2 className="text-sm font-medium">
                                        <a href={project.href}>
                                          <span className="absolute inset-0" aria-hidden="true" />
                                          {project.title}{' '}
                                          <span className="sr-only">{project.active ? 'Running' : 'Not running'}</span>
                                        </a>
                                      </h2>
                                    </span>
                                  </div>
                                  <a href={project.repoHref} className="relative group flex items-center space-x-2.5">
                                    <span className="text-sm text-gray-500 group-hover:text-gray-900 font-small truncate">
                                      {project.description}
                                    </span>
                                  </a>
                                </div>
                                <div className="sm:hidden">
                                  <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                                {/* Repo meta info */}
                                <div className="hidden sm:flex flex-col flex-shrink-0 items-end space-y-3">
                                  <p className="flex items-center space-x-4">
                                    <span
                                      className="relative text-sm text-gray-500 hover:text-gray-900 font-medium"
                                    >
                                      {project.status}
                                    </span>
                                    <button
                                      type="button"
                                      className="relative bg-white rounded-full focus:outline-none focus:border-green-900"
                                    >
                                      <span className="sr-only">
                                        {project.starred ? 'Add to favorites' : 'Remove from favorites'}
                                      </span>
                                    </button>
                                  </p>
                                  <p className="flex text-gray-500 text-sm space-x-2">
                                    <div className='flex'><ThumbUpIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /><span className='ml-1'>{project.votes}</span></div>
                                    <span aria-hidden="true">&middot;</span>
                                    <div className='flex'><ChatAltIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /><span className='ml-1'>{project.comments.length}</span></div>
                                    <span aria-hidden="true">&middot;</span>
                                    <span>{project.reportedOn}</span>
                                    
                                  </p>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </nav>
                        
                      </div>
                    </div>
                  </div>
                </div>
                {currentUser && feedbacks &&
                  <aside className="hidden xl:block xl:pl-8">
                  <h3 className="mb-4 font-medium">{currentUser.fullName}</h3>
                  <div className="space-y-5">
                    <div className="flex items-center space-x-2">
                      <LockOpenIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
                      <span className="text-green-700 text-sm font-medium">Active user</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <ChatAltIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span className="text-gray-900 text-sm font-medium">4 comments</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <ThumbUpIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span className="text-gray-900 text-sm font-medium">13 votes</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span className="text-gray-900 text-sm font-medium">
                        Member since <time dateTime="2020-12-02">Dec 2, 2020</time>
                      </span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span className="text-gray-900 text-sm font-medium">
                        Last Activity <time dateTime="2020-12-02">Dec 2, 2020</time>
                      </span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <GlobeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span className="text-gray-900 text-sm font-medium">
                        Browser <time dateTime="2020-12-02">Unknown</time>
                      </span>
                    </div>
                    
                  </div>
                
                </aside>
                }
              </div>
            </div>
          </main>

            {/* Secondary sidebar */}
            <aside className="hidden xl:order-first xl:flex xl:flex-col flex-shrink-0 w-96 border-r border-gray-200">
              <div className="px-6 pt-6 pb-4">
                <h2 className="text-lg font-medium text-gray-900">Users</h2>
                <p className="mt-1 text-sm text-gray-600">Search directory of 3,018 users</p>
                <form className="mt-6 flex space-x-4" action="#">
                  <div className="flex-1 min-w-0">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative rounded-md shadow-sm focus:outline-none focus:border-green-900">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                      <input
                        type="search"
                        name="search"
                        id="search"
                        className="block w-full pl-10 sm:text-sm border-gray-300 rounded-md focus:outline-none focus:ring-green-900 focus:border-green-900"
                        placeholder="Search"
                      />
                    </div>
                  </div>
                  {/* <button
                    type="submit"
                    className="inline-flex justify-center px-3.5 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:border-green-900"
                  >
                    <FilterIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    <span className="sr-only">Search</span>
                  </button> */}
                </form>
              </div>
              {/* Directory list */}
              <nav className="h-screen overflow-y-scroll" aria-label="Directory">
                {Object.keys(directory).map((letter) => (
                  <div key={letter} className="relative">
                    <div className="z-10 sticky top-0 border-t border-b border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
                      <h3>{letter}</h3>
                    </div>
                    <ul role="list" className="relative z-0 divide-y divide-gray-200">
                      {directory[letter].map((person) => (
                        <li key={person.id}>
                          <div onClick={()=>setCurrentUser(person)} className="relative px-6 py-5 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-900">
                            <div className="flex-shrink-0">
                              <img className="h-10 w-10 rounded-full" src={"/api/user/profilePic/" + person.id} alt="" />
                            </div>
                            <div className="flex-1 min-w-0">
                              <a href="#" className="focus:outline-none">
                                {/* Extend touch target to entire panel */}
                                <span className="absolute inset-0" aria-hidden="true" />
                                <p className="text-sm font-medium text-gray-900">{person.fullName}</p>
                                {/* <p className="text-sm text-gray-500 truncate">{person.role}</p> */}
                              </a>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </nav>
            </aside>

            
          </div>
          </>
      );
  }