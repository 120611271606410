import React, { useEffect, useRef, useState } from 'react';

export default function Pagination({totalItems, itemsPerPage, onPageChange}) {
    const [curPage, setCurPage] = useState(0);
    const internalCurPage = useRef(0);

    useEffect(()=>{
        onPageChange(curPage);
    }, [totalItems, itemsPerPage, curPage]);

    const next = () => {
        if(internalCurPage.current + 1 < Math.ceil(totalItems / itemsPerPage)) {
          internalCurPage.current = internalCurPage.current + 1;
          setCurPage(internalCurPage.current);
        }
    }

    const previous = () => {
        if(internalCurPage.current > 0) {
            internalCurPage.current = internalCurPage.current - 1;
            setCurPage(internalCurPage.current);
        }
    }

    return (
        <nav
        className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
        aria-label="Pagination"
      >
        <div className="hidden sm:block">
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{internalCurPage.current * itemsPerPage + 1}</span> to 
            <span className="font-medium"> {Math.min((internalCurPage.current+1)*itemsPerPage, totalItems)}</span> of{' '}
            <span className="font-medium">{totalItems}</span> results
          </p>
        </div>
        <div className="flex-1 flex justify-between sm:justify-end">
          <button
            onClick={previous}
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Previous
          </button>
          <button
            onClick={next}
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Next
          </button>
        </div>
      </nav>
    );
}