import React, {useState} from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function LandingPage(props) {

  const [mobMenuOpen, setMobMenuOpen] = useState(false);

  return (
      <div>
        <div className="relative bg-gray-100 overflow-hidden">
          <div className="bg-gray-50">
            <div className="relative overflow-hidden">
              <div className="absolute inset-y-0 h-full w-full"
                   aria-hidden="true">
                <div className="relative h-full">
                  <svg
                      className="absolute right-full transform translate-y-1/3 translate-x-1/4 md:translate-y-1/2 sm:translate-x-1/2 lg:translate-x-full"
                      width="404" height="784" fill="none"
                      viewBox="0 0 404 784">
                    <defs>
                      <pattern id="e229dbec-10e9-49ee-8ec3-0286ca089edf" x="0"
                               y="0" width="20" height="20"
                               patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4"
                              className="text-gray-200"
                              fill="currentColor"></rect>
                      </pattern>
                    </defs>
                    <rect width="404" height="784"
                          fill="url(#e229dbec-10e9-49ee-8ec3-0286ca089edf)"></rect>
                  </svg>
                  <svg
                      className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 sm:-translate-x-1/2 md:-translate-y-1/2 lg:-translate-x-3/4"
                      width="404" height="784" fill="none"
                      viewBox="0 0 404 784">
                    <defs>
                      <pattern id="d2a68204-c383-44b1-b99f-42ccff4e5365" x="0"
                               y="0" width="20" height="20"
                               patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4"
                              className="text-gray-200"
                              fill="currentColor"></rect>
                      </pattern>
                    </defs>
                    <rect width="404" height="784"
                          fill="url(#d2a68204-c383-44b1-b99f-42ccff4e5365)"></rect>
                  </svg>
                </div>
              </div>
              <div className="relative pt-6 pb-16 sm:pb-24">
                <div>
                  <div className="max-w-7xl mx-auto px-4 sm:px-6">
                    <nav
                        className="relative flex items-center justify-between sm:h-10 md:justify-center"
                        aria-label="Global">
                      <div
                          className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                        <div
                            className="flex items-center justify-between w-full md:w-auto">
                          <a href="#"><span className="sr-only">Workflow</span>
                            <img className="h-10 w-auto sm:h-10"
                                 src="/img/jf-icon.svg" alt=""/></a>
                          <div className="-mr-2 items-center md:hidden">
                            <button type="button"
                                    className="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500"
                                    onClick={()=>setMobMenuOpen(!mobMenuOpen)}
                                    aria-expanded="false">
                              <span
                                className="sr-only">Open main menu</span>
                              <svg className="h-6 w-6"
                                   xmlns="http://www.w3.org/2000/svg"
                                   fill="none"
                                   viewBox="0 0 24 24" stroke="currentColor"
                                   aria-hidden="true">
                                <path strokeLinecap="round"
                                      strokeLinejoin="round" strokeWidth="2"
                                      d="M4 6h16M4 12h16M4 18h16"></path>
                              </svg>
                            </button>
                            <div className={classNames(mobMenuOpen ? "md:hidden absolute bg-gray-200 p-3 right-0" : "hidden")}>
                              <ul>
                                <li className="m-2"><a href="#"
                                      className="font-medium text-gray-500 hover:text-gray-900">Product</a></li>
                                <li className="m-2"><a href="#"
                                      className="font-medium text-gray-500 hover:text-gray-900">Features</a></li>
                                <li className="m-2"><a href="#"
                                      className="font-medium text-gray-500 hover:text-gray-900">Marketplace</a></li>
                                <li className="m-2"><a href="#"
                                      className="font-medium text-gray-500 hover:text-gray-900">Company</a></li>
                                <li className="m-2">
                                  <span className="inline-flex rounded-md">
                                    <a
                                      href="/login"
                                      className="inline-flex items-center px-2 py-1 border border-transparent text-base font-medium rounded-md text-green-600 bg-white hover:text-green-500">Log in </a>
                                  </span>
                                </li>
                                <li className="m-2">
                                  <a
                                    href="/register"
                                    className="whitespace-nowrap inline-flex items-center justify-center px-2 py-1 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green-800 hover:bg-green-700">
                                    Get Started</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="hidden md:flex md:space-x-10">
                        <a href="#"
                           className="font-medium text-gray-500 hover:text-gray-900">Product</a>
                        <a href="#"
                           className="font-medium text-gray-500 hover:text-gray-900">Features</a>
                        <a href="#"
                           className="font-medium text-gray-500 hover:text-gray-900">Marketplace</a>
                        <a href="#"
                           className="font-medium text-gray-500 hover:text-gray-900">Company</a>
                      </div>
                      <div
                          className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
                      <span className="inline-flex rounded-md shadow">
                        <a
                          href="/login"
                          className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-green-600 bg-white hover:text-green-500">Log in </a>
                      </span>
                      <a
                          href="/register"
                          className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green-800 hover:bg-green-700">
                        Get Started</a></div>
                    </nav>
                  </div>
                </div>
                <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
                  <div className="text-center"><h1
                      className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                    <span className="block">Build better products using</span>
                    <span
                        className="block text-green-600">customer feedbacks</span>
                  </h1><p
                      className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">Beautifully
                    track and manage all your customer feedbacks in one
                    place.</p>
                  </div>
                </div>
              </div>
              <div className="relative">
                <div className="absolute inset-0 flex flex-col"
                     aria-hidden="true">
                  <div className="flex-1"></div>
                  <div className="flex-1 w-full bg-gray-800"></div>
                </div>
                <div className="max-w-7xl mx-auto px-4 sm:px-6"><img
                    className="relative rounded-lg shadow-lg"
                    src="/img/jf-client.svg" alt="App screenshot"/></div>
              </div>
            </div>
            <div className="bg-gray-800">
              <div
                  className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                <h2 className="text-center text-gray-400 text-sm font-semibold uppercase tracking-wide">Trusted
                  by over 26,000 forward-thinking companies</h2>
                <div
                    className="mt-8 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
                  <div
                      className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                    <img className="h-12"
                         src="https://tailwindui.com/img/logos/tuple-logo-gray-400.svg"
                         alt="Tuple"/></div>
                  <div
                      className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                    <img className="h-12"
                         src="https://tailwindui.com/img/logos/mirage-logo-gray-400.svg"
                         alt="Mirage"/></div>
                  <div
                      className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                    <img className="h-12"
                         src="https://tailwindui.com/img/logos/statickit-logo-gray-400.svg"
                         alt="StaticKit"/></div>
                  <div
                      className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
                    <img className="h-12"
                         src="https://tailwindui.com/img/logos/transistor-logo-gray-400.svg"
                         alt="Transistor"/></div>
                  <div
                      className="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
                    <img className="h-12"
                         src="https://tailwindui.com/img/logos/workcation-logo-gray-400.svg"
                         alt="Workcation"/></div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
            <div
                className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
              <svg
                  className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
                  width="404" height="784" fill="none" viewBox="0 0 404 784"
                  aria-hidden="true">
                <defs>
                  <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0"
                           width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4"
                          className="text-gray-200" fill="currentColor"></rect>
                  </pattern>
                </defs>
                <rect width="404" height="784"
                      fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"></rect>
              </svg>
              <div
                  className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                <div className="relative"><h3
                    className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">Put
                  your Customers first</h3><p
                    className="mt-3 text-lg text-gray-500">A radically better
                  way
                  for the best product teams to make something people want.</p>
                  <dl className="mt-10 space-y-10">
                    <div className="relative">
                      <dt>
                        <div
                            className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-green-500 text-white">
                          <svg className="h-6 w-6"
                               xmlns="http://www.w3.org/2000/svg" fill="none"
                               viewBox="0 0 24 24" stroke="currentColor"
                               aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"></path>
                          </svg>
                        </div>
                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Make
                          informed decisions</p></dt>
                      <dd className="mt-2 ml-16 text-base text-gray-500">Prioritise
                        your features based on customer feedback. Help your team
                        to plan, build, and release great products.
                      </dd>
                    </div>
                    <div className="relative">
                      <dt>
                        <div
                            className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-green-500 text-white">
                          <svg className="h-6 w-6"
                               xmlns="http://www.w3.org/2000/svg" fill="none"
                               viewBox="0 0 24 24" stroke="currentColor"
                               aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"></path>
                          </svg>
                        </div>
                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Keep
                          users engaged</p></dt>
                      <dd className="mt-2 ml-16 text-base text-gray-500">Automatically
                        send updates to all voted users when the feature is
                        shipped or you have an update.
                      </dd>
                    </div>
                    <div className="relative">
                      <dt>
                        <div
                            className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-green-500 text-white">
                          <svg className="h-6 w-6"
                               xmlns="http://www.w3.org/2000/svg" fill="none"
                               viewBox="0 0 24 24" stroke="currentColor"
                               aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M13 10V3L4 14h7v7l9-11h-7z"></path>
                          </svg>
                        </div>
                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Include
                          your users in product development</p></dt>
                      <dd className="mt-2 ml-16 text-base text-gray-500">Listening
                        to yours users is a time tested way to build better
                        products. Some of the world's best companies built their
                        product listening to their users.
                      </dd>
                    </div>
                  </dl>
                </div>
                <div className="mt-10 -mx-4 relative lg:mt-0"
                     aria-hidden="true">
                  <svg
                      className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                      width="784" height="404" fill="none"
                      viewBox="0 0 784 404">
                    <defs>
                      <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941" x="0"
                               y="0" width="20" height="20"
                               patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4"
                              className="text-gray-200"
                              fill="currentColor"></rect>
                      </pattern>
                    </defs>
                    <rect width="784" height="404"
                          fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"></rect>
                  </svg>
                  <img className="relative mx-auto" width="690"
                       src="/img/feature-image.svg" alt=""/></div>
              </div>
              <svg
                  className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
                  width="404" height="784" fill="none" viewBox="0 0 404 784"
                  aria-hidden="true">
                <defs>
                  <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0"
                           width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4"
                          className="text-gray-200" fill="currentColor"></rect>
                  </pattern>
                </defs>
                <rect width="404" height="784"
                      fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"></rect>
              </svg>
            </div>
          </div>
        </div>
        <div className="bg-gray-100">
          <div className="pt-12 sm:pt-16 lg:pt-20">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="text-center"><h2
                  className="text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl">Simple
                no-tricks pricing</h2><p
                  className="mt-4 text-xl text-gray-600">Our pricing can't be
                simpler. Tell us if you have any ideas.</p></div>
            </div>
          </div>
          <div className="mt-8 bg-white pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
            <div className="relative">
              <div className="absolute inset-0 h-1/2 bg-gray-100"></div>
              <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div
                    className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
                  <div className="flex-1 bg-white px-6 py-8 lg:p-12"><h3
                      className="text-2xl font-extrabold text-green-600 sm:text-3xl">Simple
                    Monthly Payments per board.</h3><p
                      className="mt-6 text-base text-gray-500">Includes custom
                    branding, unlimited tracked users, unlimited boards.</p>
                    <div className="mt-8">
                      <div className="flex items-center"><h4
                          className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-green-600">What's
                        included</h4>
                        <div
                            className="flex-1 border-t-2 border-gray-200"></div>
                      </div>
                      <ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                        <li className="flex items-start lg:col-span-1">
                          <div className="flex-shrink-0">
                            <svg className="h-5 w-5 text-green-700"
                                 xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 20 20" fill="currentColor"
                                 aria-hidden="true">
                              <path fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clipRule="evenodd"></path>
                            </svg>
                          </div>
                          <p className="ml-3 text-sm text-gray-700">Custom
                            branding and domain</p></li>
                        <li className="flex items-start lg:col-span-1">
                          <div className="flex-shrink-0">
                            <svg className="h-5 w-5 text-green-700"
                                 xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 20 20" fill="currentColor"
                                 aria-hidden="true">
                              <path fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clipRule="evenodd"></path>
                            </svg>
                          </div>
                          <p className="ml-3 text-sm text-gray-700">Unlimited
                            tracked users</p></li>
                        <li className="flex items-start lg:col-span-1">
                          <div className="flex-shrink-0">
                            <svg className="h-5 w-5 text-green-700"
                                 xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 20 20" fill="currentColor"
                                 aria-hidden="true">
                              <path fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clipRule="evenodd"></path>
                            </svg>
                          </div>
                          <p className="ml-3 text-sm text-gray-700">Unlimited
                            boards</p></li>
                        <li className="flex items-start lg:col-span-1">
                          <div className="flex-shrink-0">
                            <svg className="h-5 w-5 text-green-700"
                                 xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 20 20" fill="currentColor"
                                 aria-hidden="true">
                              <path fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clipRule="evenodd"></path>
                            </svg>
                          </div>
                          <p className="ml-3 text-sm text-gray-700">No tiered
                            pricing</p></li>
                      </ul>
                    </div>
                  </div>
                  <div
                      className="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                    <p className="text-lg leading-6 font-medium text-gray-900">Subscription</p>
                    <div
                        className="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
                      <span>$10/month </span><span
                        className="ml-3 text-xl font-medium text-gray-500">USD</span>
                    </div>
                    <div className="mt-6">
                      <div className="rounded-md shadow"><a
                          href="https://just-feedback.netlify.app/"
                          className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-700 hover:bg-green-900">Get
                        Started</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative bg-gray-800">
          <div
              className="h-56 bg-gradient-to-r from-green-500 to-green-700 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
            <img className="w-full h-full object-cover" src="/img/img-home.jpeg"
                 alt="Support team"/>
            <div
                className="absolute inset-0 bg-gradient-to-r from-green-500 to-green-700 mix-blend-multiply"
                aria-hidden="true"></div>
          </div>
          <div
              className="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
            <div className="md:ml-auto md:w-1/2 md:pl-10">
              <div
                  className="text-base leading-6 font-semibold uppercase tracking-wider text-gray-300">SUPPORT
                YOU CAN COUNT ON
              </div>
              <h2 className="mt-2 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">Happiest
                support team :)</h2><p
                className="mt-3 text-lg leading-7 text-gray-300">Our support
              folks
              are known to go out of the way to help founders. Open a chat and
              see
              it for yourself</p>
              <div className="mt-8 sm:flex">
                <div className="mt-3 sm:mt-0">
                  <div onClick={()=>window.Beacon('open')}
                       className="cursor-pointer flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-700 bg-white hover:text-white hover:bg-green-900 focus:outline-none focus:shadow-outline focus:border-green-300 transition duration-150 ease-in-out">Chat
                    with us now →
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img height="1" width="1" style={{display:'none'}} alt=""
             src="https://px.ads.linkedin.com/collect/?pid=2549562&amp;conversionId=3100460&amp;fmt=gif"/>
        <img height="1" width="1" style={{display:'none'}} alt=""
             src="https://px.ads.linkedin.com/collect/?pid=2549562&amp;conversionId=3100468&amp;fmt=gif"/>
        <img height="1" width="1" style={{display:'none'}} alt=""
             src="https://px.ads.linkedin.com/collect/?pid=2549562&amp;conversionId=3146172&amp;fmt=gif"/>
        <footer className="bg-white">
          <div
              className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
            <nav className="-mx-5 -my-2 flex flex-wrap justify-center"
                 aria-label="Footer">
              <div className="px-5 py-2"><a href="#"
                                            className="text-base text-gray-500 hover:text-gray-900">About</a>
              </div>
              <div className="px-5 py-2"><a href="#"
                                            className="text-base text-gray-500 hover:text-gray-900">Blog</a>
              </div>
              <div className="px-5 py-2"><a href="#"
                                            className="text-base text-gray-500 hover:text-gray-900">Jobs</a>
              </div>
              <div className="px-5 py-2"><a href="#"
                                            className="text-base text-gray-500 hover:text-gray-900">Press</a>
              </div>
              <div className="px-5 py-2"><a href="#"
                                            className="text-base text-gray-500 hover:text-gray-900">Accessibility</a>
              </div>
              <div className="px-5 py-2"><a href="#"
                                            className="text-base text-gray-500 hover:text-gray-900">Partners</a>
              </div>
            </nav>
            <div className="mt-8 flex justify-center space-x-6"><a href="#"
                                                                   className="text-gray-400 hover:text-gray-500"><span
                className="sr-only">Twitter</span>
              <svg className="h-6 w-6" fill="currentColor"
                   viewBox="0 0 24 24" aria-hidden="true">
                <path
                    d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
              </svg>
            </a></div>
            <p className="mt-8 text-center text-base text-gray-400">© 2021
              JustFeedback, Inc. All rights reserved.</p></div>
        </footer>
        <div id="beacon-container">
          <div className="hsds-beacon">
            <div className="Beacon">
              <div className="sc-AxheI gkgGqG"></div>
              <div
                  className="sc-AxhCb cszqFf BeaconContainer is-configDisplayRight"
                  data-css-with-delay="false"></div>
              <div className="sc-fznyAO guMowJ BeaconFabButtonFrame"
                   style={{borderRadius: 55, height: 55, position: 'fixed', transform: 'scale(1)', width: 138, zIndex: 1049}}>
                <iframe aria-label="Toggle Customer Support" id=""
                        data-cy="FrameComponent"></iframe>
              </div>
              <span></span><span></span></div>
          </div>
        </div>
      </div>);
}