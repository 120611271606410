import React, { useEffect, useState } from 'react';

import {
  QuestionMarkCircleIcon
} from '@heroicons/react/outline';
import serviceClient from '../helpers/ServiceClient';
import { toast } from 'react-toastify';

export default function AccountSettings() {


  const [fullName, setFullName] = useState(null);
  const [email, setEmail] = useState(null);
  const [address, setAddress] = useState(null);
  const [state, setState] = useState(null);
  const [country, setCountry] = useState(null);
  const [zipcode, setZipcode] = useState(null);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const [user, setUser] = useState({});

  useEffect(()=>{
    refreshUser();
  }, []);

  const clear = () => {
    setFullName(null);
    setEmail(null);
    setAddress(null);
    setState(null);
    setCountry(null);
    setZipcode(null);
  }

  const clearPass = () => {
    setOldPassword("");
    setNewPassword("");
  }

  const save = () => {
    serviceClient.updateUser({
      fullName: fullName,
      email: email,
      address: address,
      state: state,
      country: country,
      zipcode: zipcode
    },
      ()=>{
        refreshUser(clear);
        toast("Successfully updated account settings");
      });
  };

  const changePass = () => {
    serviceClient.updatePassword({
      oldPassword: oldPassword,
      newPassword: newPassword
    }, ()=>{
      toast("Successfully changed password");
    },
    (e)=>{
      toast.error(e.message);
    })
  }

  const refreshUser = (callback) => {
    serviceClient.getUser((response)=>{
      setUser(response.data);
      if(callback) callback();
    })
  };

    return (
        <main className="h-screen flex-1 relative z-0 overflow-y-scroll focus:outline-none xl:order-last">
            <div className="flex-1 xl:overflow-y-auto">
                  <div className="max-w-3xl mx-auto py-10 px-4 sm:px-6 lg:py-12 lg:px-8">
                    <h1 className="mb-6 text-3xl font-extrabold text-blue-gray-900">Account</h1>
                    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                      <section aria-labelledby="payment-details-heading">
                        <div action="#" method="POST">
                          <div className="shadow sm:rounded-md sm:overflow-hidden">
                            <div className="bg-white py-6 px-4 sm:p-6">
                              <div>
                                <h2 id="payment-details-heading" className="text-lg leading-6 font-medium text-gray-900">
                                  Profile
                                </h2>
                              </div>

                              <div className="mt-6 grid grid-cols-4 gap-6">
                                <div className="col-span-2 sm:col-span-2">
                                  <label htmlFor="full-name" className="block text-sm font-medium text-gray-700">
                                    Full name
                                  </label>
                                  <input
                                    value={fullName ? fullName : user.fullName}
                                    onChange={(e)=>setFullName(e.target.value)}
                                    type="text"
                                    name="full-name"
                                    id="full-name"
                                    autoComplete="cc-given-name"
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-900 focus:border-green-900 sm:text-sm"
                                  />
                                </div>


                                <div className="col-span-2 sm:col-span-2">
                                  <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                                    Email address
                                  </label>
                                  <input
                                    value={email ? email : user.email}
                                    onChange={(e)=>setEmail(e.target.value)}
                                    type="text"
                                    name="email-address"
                                    id="email-address"
                                    autoComplete="email"
                                    disabled={true}
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-900 focus:border-green-900 sm:text-sm"
                                  />
                                </div>

                                <div className="col-span-2 sm:col-span-2">
                                  <label htmlFor="expiration-date" className="block text-sm font-medium text-gray-700">
                                    Street address
                                  </label>
                                  <input
                                    value={address ? address : user.address}
                                    onChange={(e)=>setAddress(e.target.value)}
                                    type="text"
                                    name="expiration-date"
                                    id="expiration-date"
                                    autoComplete="cc-exp"
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-900 focus:border-green-900 sm:text-sm"
                                  />
                                </div>

                                <div className="col-span-2 sm:col-span-2">
                                  <label
                                    htmlFor="state-code"
                                    className="flex items-center text-sm font-medium text-gray-700"
                                  >
                                    <span>State</span>
                                    <QuestionMarkCircleIcon
                                      className="ml-1 flex-shrink-0 h-5 w-5 text-gray-300"
                                      aria-hidden="true"
                                    />
                                  </label>
                                  <input
                                    value={state ? state : user.state}
                                    onChange={(e)=>setState(e.target.value)}
                                    type="text"
                                    name="state-code"
                                    id="state-code"
                                    autoComplete="cc-csc"
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-900 focus:border-green-900 sm:text-sm"
                                  />
                                </div>

                                <div className="col-span-4 sm:col-span-2">
                                  <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                    Country
                                  </label>
                                  <select
                                    value={country ? country : user.country}
                                    onChange={(e)=>setCountry(e.target.value)}
                                    id="country"
                                    name="country"
                                    autoComplete="country-name"
                                    className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-900 focus:border-green-900 sm:text-sm"
                                  >
                                    <option value="United States">United States</option>
                                    <option value="Canada">Canada</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="India">India</option>
                                  </select>
                                </div>

                                <div className="col-span-4 sm:col-span-2">
                                  <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                                    ZIP / Postal code
                                  </label>
                                  <input
                                    value={zipcode ? zipcode : user.zipcode}
                                    onChange={(e)=>setZipcode(e.target.value)}
                                    type="text"
                                    name="postal-code"
                                    id="postal-code"
                                    autoComplete="postal-code"
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-900 focus:border-green-900 sm:text-sm"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="pt-8 flex justify-end mb-6 mr-4">
                              <button
                                type="button"
                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-blue-gray-900 hover:bg-blue-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-900"
                              >
                                Cancel
                              </button>
                              <button
                                onClick={save}
                                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-800 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-900"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </section>
                      </div>

                      <div className="mt-6 space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                      <section aria-labelledby="payment-details-heading">
                        <div action="#" method="POST">
                          <div className="shadow sm:rounded-md sm:overflow-hidden">
                            <div className="bg-white py-6 px-4 sm:p-6">
                              <div>
                                <h2 id="payment-details-heading" className="text-lg leading-6 font-medium text-gray-900">
                                  Change Password
                                </h2>
                              </div>

                              <div className="mt-6 grid grid-cols-4 gap-6">
                                <div className="col-span-4 sm:col-span-4">
                                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                    Current Password
                                  </label>
                                  <input
                                    value={oldPassword}
                                    onChange={(e)=>setOldPassword(e.target.value)}
                                    type="password"
                                    name="password"
                                    id="password"
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-900 focus:border-green-900 sm:text-sm"
                                  />
                                </div>

                                <div className="col-span-4 sm:col-span-4">
                                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                    New Password
                                  </label>
                                  <input
                                    value={newPassword}
                                    onChange={(e)=>setNewPassword(e.target.value)}
                                    type="password"
                                    name="new-password"
                                    id="new-password"
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-900 focus:border-green-900 sm:text-sm"
                                  />
                                </div>

                              </div>
                            </div>
                            <div className="pt-8 flex justify-end mb-6 mr-4">
                              <button
                                onClick={clearPass}
                                type="button"
                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-blue-gray-900 hover:bg-blue-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-900"
                              >
                                Reset
                              </button>
                              <button
                                onClick={changePass}
                                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-800 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-900"
                              >
                                Change Password
                              </button>
                            </div>
                          </div>
                        </div>
                      </section>
                      </div>

                    
                  </div>
                </div>
            </main>
    );
}