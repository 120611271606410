import React, {useContext} from 'react';

import { CheckCircleIcon } from '@heroicons/react/solid'
import serviceClient from '../helpers/ServiceClient';
import {EnterpriseContext} from '../components/EnterpriseContext';
import DefaultHeader from "../components/DefaultHeader";

const includedFeatures = [
  'Custom branding and domain',
  'Unlimited tracked users',
  'Unlimited boards',
  'No tiered pricing',
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


export default function BillingPage(props) {

  const enterprise = useContext(EnterpriseContext);

  const checkoutSubscription = () => {
    serviceClient.checkoutSubscription({
      priceId: "price_1KUTOFL03emDti6e59pLfz52"
    },
    (response)=>{
      window.location = response.data;
    });
  }

  if(props.match.params && props.match.params.page == 'success') {
    return (
      <>
        <DefaultHeader setMobileMenuOpen={props.setMobileMenuOpen} setSideBarOpen={props.setSidebarOpen}/>
        <div className="h-screen bg-gray-100">
          <div className="pt-12 sm:pt-16 lg:pt-20">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="text-center">
                <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl">Payment Complete</h2>
                <p className="mt-4 text-xl text-gray-600">
                  We may take upto 5 minute to process your payments
                </p>
              </div>
            </div>
          </div>
        </div>
        </>
    );
  }

  if(props.match.params && props.match.params.page == 'cancelled') {
    return (
      <>
        <DefaultHeader setMobileMenuOpen={props.setMobileMenuOpen} setSideBarOpen={props.setSidebarOpen}/>
        <div className="h-screen bg-gray-100">
          <div className="pt-12 sm:pt-16 lg:pt-20">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="text-center">
                <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl">Payment Cancelled</h2>
                <p className="mt-4 text-xl text-gray-600">
                  Please retry payment. If money is deducted from your bank account. It will be refunded within 10 working days.
                </p>
              </div>
            </div>
          </div>
        </div>
        </>
    );
  }

    return (
      <>
        <DefaultHeader setMobileMenuOpen={props.setMobileMenuOpen} setSideBarOpen={props.setSidebarOpen}/>
      <div className="h-screen bg-gray-100">
      <div className="pt-12 sm:pt-16 lg:pt-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl">Simple no-tricks pricing</h2>
            <p className="mt-4 text-xl text-gray-600">
            Our pricing can't be simpler. Tell us if you have any ideas.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-8 pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
        <div className="relative">
          <div className="absolute inset-0 h-1/2 bg-gray-100" />
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
              <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                <h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">Simple Monthly Payments per board.</h3>
                <p className="mt-6 text-base text-gray-500">
                Includes custom branding, unlimited tracked users, unlimited boards.
                </p>
                <div className="mt-8">
                  <div className="flex items-center">
                    <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-green-600">
                      What's included
                    </h4>
                    <div className="flex-1 border-t-2 border-gray-200" />
                  </div>
                  <ul role="list" className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                    {includedFeatures.map((feature) => (
                      <li key={feature} className="flex items-start lg:col-span-1">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-green-700" aria-hidden="true" />
                        </div>
                        <p className="ml-3 text-sm text-gray-700">{feature}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                <p className="text-lg leading-6 font-medium text-gray-900">Pay once, own it forever</p>
                <div className="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
                  <span>$10/month</span>
                  <span className="ml-3 text-xl font-medium text-gray-500">USD</span>
                </div>
                <div className="mt-6">
                  <div className="rounded-md shadow">
                    <div>
                      <button
                          onClick={checkoutSubscription}
                          className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-800 hover:bg-green-700"
                          type="submit">Subscribe</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        </>
    );
}