import React from "react";
import { Redirect, Route } from "react-router-dom";

export default function PrivateRoute ({render: renderProp, authed, accountActive, ...rest}) {

    if(authed == null || accountActive == null) {
        return (<div>Wait...</div>)
    }

    return (
      <Route
        {...rest}
        render={(authed === true && accountActive === true)
          ? renderProp
          : ((authed == true) ? (props) => <Redirect to={{pathname: '/signup-progress', state: {from: props.location}}} />
            : (props) => <Redirect to={{pathname: '/login', state: {from: props.location}}} />)}
      />
    )
  }