import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import serviceClient from "../helpers/ServiceClient";
import { useHistory } from 'react-router-dom';
import {XCircleIcon, CheckIcon, EyeIcon} from '@heroicons/react/outline';
import ErrorBanner from '../components/ErrorBanner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEyeSlash, faEye} from '@fortawesome/free-regular-svg-icons'
import OAuth2Login from 'react-simple-oauth2-login';
import {TEST_MODE} from '../config/Constants';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


  export default function RegisterPage() {
    const [email, setEmail] = useState('');
    const [subdomain, setSubdomain] = useState('');
    const [password, setPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [signUpSuccess, setSignUpSuccess] = useState(false);
    const [signUpInProgress, setSignUpInProgress] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleFailureGoogle = (result) => {
      // alert(result);
    };

    const handleLoginGoogle = async (googleData) => {
      console.log(googleData);
      serviceClient.signup(
          {
            subdomain: subdomain,
            token: googleData.access_token,
            provider: "GOOGLE"
          },
          ()=>{setSignUpSuccess(true); setSignUpInProgress(false);},
          (e)=>{setErrorMsg(e.message); setSignUpInProgress(false);}
      );
    };

    const submit = () => {
      setSignUpInProgress(true);
      serviceClient.signup(
        {
          email: email,
          subdomain: subdomain,
          password: password
        },
        ()=>{setSignUpSuccess(true); setSignUpInProgress(false);},
        (e)=>{setErrorMsg(e.message); setSignUpInProgress(false);}
      );
    };

    return (
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src="/jf-icon.svg"
          alt="Workflow"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Start your 14-day free trial</h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Or{' '}
          <a href="/login" className="text-lg text-green-600 hover:text-green-500">
            sign into your account
          </a>
        </p>
      </div>

    <ErrorBanner msg={errorMsg} />

    <Transition.Root closeOnOverlayClick={false} show={signUpSuccess} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={()=>{}}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Signup Successful
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                    <a className='underline text-indigo-600' href="/login">Click here to Login to your account</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-6">
          <div className="">
            <label htmlFor="subdomain" className="block text-sm font-medium text-gray-700">
              Pick a sub-domain name
            </label>

            <div className="mt-1 flex rounded-md shadow-sm">
              <input
                  value={subdomain}
                  onChange={(e)=>{setSubdomain(e.target.value)}}
                  required
                  type="text"
                  name="subdomain"
                  id="subdomain"
                  autoComplete="subdomain"
                  defaultValue=""
                  className="flex-1 block rounded-l-md w-full min-w-0 border-gray-400 sm:text-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
              />
              <span className="inline-flex items-center px-3 rounded-none rounded-r-md  border border-r-1 border-l-0 border-gray-400 bg-blue-gray-900 text-blue-gray-500 sm:text-sm">
                            .justfeedback.co
                          </span>
            </div>

            <div className="mt-1">
            </div>
          </div>

          <div className="bg-white py-8 px-2 mt-2 border border-gray-100 rounded-lg">
            <div className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e)=>{setEmail(e.target.value)}}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-1 flex">
                  <input
                    id="password"
                    name="password"
                    type={showPassword?"test":"password"}
                    value={password}
                    onChange={(e)=>{setPassword(e.target.value)}}
                    autoComplete="current-password"
                    required
                    className="appearance-none border-r-0 block w-full px-3 py-2 border border-gray-300 rounded-l-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                  />
                  <span onClick={()=>setShowPassword(!showPassword)}  className="appearance-none  cursor-pointer inline-flex items-center w-10 px-2 py-2 rounded-none rounded-r-md  border border-r-1 border-l-0 border-gray-300 sm:text-sm">
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye } />
                  </span>
                </div>
              </div>

              <div>
                <button
                  onClick={submit}
                  disabled={signUpInProgress}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                  Sign up
                </button>
              </div>
            </div>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">Or continue with</span>
                </div>
              </div>

              <div className="mt-6 grid grid-cols-3 gap-3">
                <div>
                  <OAuth2Login
                      render={renderProps => (
                          <a onClick={renderProps.onClick} disabled={renderProps.disabled} className="cursor-pointer w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                            <span className="sr-only">Sign in with Google</span>
                            <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 32 32">
                              <path d="M 16.003906 14.0625 L 16.003906 18.265625 L 21.992188 18.265625 C 21.210938 20.8125 19.082031 22.636719 16.003906 22.636719 C 12.339844 22.636719 9.367188 19.664063 9.367188 16 C 9.367188 12.335938 12.335938 9.363281 16.003906 9.363281 C 17.652344 9.363281 19.15625 9.96875 20.316406 10.964844 L 23.410156 7.867188 C 21.457031 6.085938 18.855469 5 16.003906 5 C 9.925781 5 5 9.925781 5 16 C 5 22.074219 9.925781 27 16.003906 27 C 25.238281 27 27.277344 18.363281 26.371094 14.078125 Z"></path>
                            </svg>
                          </a>
                      )}
                      authorizationUrl="https://accounts.google.com/o/oauth2/v2/auth"
                      scope="email profile"
                      onSuccess={handleLoginGoogle}
                      onFailure={handleFailureGoogle}
                      responseType="token"
                      onRequest={()=>{}}
                      redirectUri={TEST_MODE ? "https://tolocalhost.com/register" : "https://justfeedback.co/register"}
                      cookiePolicy={'single_host_origin'}
                      clientId="470952880901-l32ko8s9ugq1nebo5m4h0vqf93iv5vqa.apps.googleusercontent.com" />
                </div>

                <div>
                  <a
                    href="#"
                    className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span className="sr-only">Sign in with Twitter</span>
                    <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </a>
                </div>

                <div>
                  <a
                    href="#"
                    className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span className="sr-only">Sign in with GitHub</span>
                    <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fillRule="evenodd"
                        d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }