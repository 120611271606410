import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import serviceClient from "../helpers/ServiceClient";

import {
    ChevronRightIcon,
    ThumbUpIcon,
    ChatAltIcon
} from '@heroicons/react/outline';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


export default function FeedbackItem(props) {

    const [feedback, setFeedback] = useState(null);


    useEffect(()=>{
        refreshFeedback();
    }, [props.feedbackId]);

    const refreshFeedback = () => {
        serviceClient.getFeedbackDetails({feedbackId: props.feedbackId}, (response)=>{setFeedback(response.data)});
    };


    return (
        feedback && 
        <li onClick={props.onClick}
            className={props.selected ? "relative pl-4 pr-6 py-5 bg-gray-100 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6" : "relative pl-4 pr-6 py-5 hover:bg-gray-50 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6"}
        >
            <div className="flex items-center justify-between space-x-4">
            {/* Repo name and link */}
            <div className="min-w-0 space-y-3">
                <div className="flex items-center space-x-3">
                <span
                    className={classNames(
                        feedback.status != 'CLOSED' ? 'bg-green-100' : 'bg-gray-100',
                    'h-4 w-4 rounded-full flex items-center justify-center'
                    )}
                    aria-hidden="true"
                >
                    <span
                    className={classNames(
                        feedback.status != 'CLOSED' ? 'bg-green-400' : 'bg-gray-400',
                        'h-2 w-2 rounded-full'
                    )}
                    />
                </span>

                <span className="block">
                    <h2 className="text-sm font-medium">
                    <a href={feedback.href}>
                        <span className="absolute inset-0" aria-hidden="true" />
                        {feedback.title}{' '}
                        <span className="sr-only">{feedback.status != 'CLOSED' ? 'Running' : 'Not running'}</span>
                    </a>
                    </h2>
                </span>
                </div>
                <a href={feedback.repoHref} className="relative group flex items-center space-x-2.5">
                <span className="text-sm text-gray-500 group-hover:text-gray-900 font-small truncate w-64">
                    {feedback.description}
                </span>
                </a>
            </div>
            <div className="sm:hidden">
                <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            {/* Repo meta info */}
            <div className="hidden sm:flex flex-col flex-shrink-0 items-end space-y-3">
                <p className="flex items-center space-x-4">
                <span
                    className="relative text-sm text-gray-500 hover:text-gray-900 font-medium"
                >
                    {feedback.status}
                </span>
                </p>
                <div className="flex text-gray-500 text-sm space-x-2">
                        <div className='flex'><ThumbUpIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /><span className='ml-1'>{feedback.votes}</span></div>
                        <span aria-hidden="true">&middot;</span>
                        <div className='flex'><ChatAltIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /><span className='ml-1'>{feedback.comments.length}</span></div>
                        <span aria-hidden="true">&middot;</span>
                        <span>{format(new Date(feedback.createdOn), "dd MMM, yy")}</span>   
                </div>
            </div>
            </div>
        </li>
    );
}