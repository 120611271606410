import React, { useContext, useEffect, useState } from 'react';
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'
import { useParams } from "react-router-dom";
import serviceClient from "../helpers/ServiceClient";
import PublicFeedbackItem from '../components/PublicFeedbackItem';
import ReactPaginate from 'react-paginate';
import { tr } from 'date-fns/locale';
import { UserContext } from '../components/UserContext';
import { toast } from 'react-toastify';

// import 'bootstrap/dist/css/bootstrap.min.css';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


  export default function PublicFeedbacksPage(props) {

    const [open, setOpen] = useState(false);
    const cancelButtonRef = useRef(null);
    const [feedbackIds, setFeedbackIds] = useState([]);
    const { board } = useParams();

    // New feedback related states
    const [featureTitle, setFeatureTitle] = useState('');
    const [featureDescription, setFeatureDescription] = useState('');
    const [requesterName, setRequesterName] = useState('');
    const [requesterEmail, setRequesterEmail] = useState('');

    // Filtering related states
    const [category, setCategory] = useState("ALL");
    const [sortBy, setSortBy] = useState("DATE");
    const [datePosted, setDatePosted] = useState("ALL");

    // Pagination related states
    const ITEMS_PER_PAGE = 10;
    const [allItem, setAllItems] = useState([]);
    const [pageCount, setPageCount] = useState(2);
    const [itemOffset, setItemOffset] = useState(0);

    const userContext = useContext(UserContext);

    const handlePageClick = (event) => {
      console.log("Event selected is " + event.selected);
      const newOffset = (event.selected * ITEMS_PER_PAGE) % allItem.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
      setFeedbackIds(allItem.slice(newOffset, newOffset + ITEMS_PER_PAGE));
    };


    useEffect(()=>{getFeedbacks();}, [category, sortBy, datePosted, userContext]);

    const submitFeedback = () => {
      if(userContext && userContext.email && userContext.fullName) {
        serviceClient.saveFeedback({
          title: featureTitle,
          description: featureDescription,
          fullName: requesterName,
          userEmail: requesterEmail,
          subdomain: props.subdomain,
          board: board,
        },
        ()=>{
          toast("Submitted the feedback");
          getFeedbacks();
          setFeatureTitle("");
          setRequesterEmail("");
          setRequesterName("");
          setFeatureDescription("");
        },
        (err)=>{
          toast.error(err.message);
        });
      } else {
        setOpen(true);
      }

    }

    const getFeedbacks = () => {

      let startTime = null;
      let endTime = null;

      if(datePosted != "ALL") {
        startTime = new Date(datePosted + '-01-01T00:00:00').getTime();
        endTime = new Date(datePosted + '-12-31T23:59:59').getTime();
      }

      serviceClient.getAllFeedbackIds({
        subdomain: props.subdomain,
        status: category,
        startTime: startTime,
        endTime: endTime
      },
        (response)=>{
          setAllItems(response.data);
          setFeedbackIds(response.data.slice(itemOffset, itemOffset+ITEMS_PER_PAGE));
          setPageCount(response.data.length / ITEMS_PER_PAGE);
        })
    }

    const login = () => {
      serviceClient.authenticateCustomer({username: requesterEmail, fullName: requesterName},
        ()=>{props.loginCallback(); setOpen(false);});
    }

    const handleCategory = (e) => {
      setCategory(e.currentTarget.value);
    };

    const handleSortBy = (e) => {
      setSortBy(e.currentTarget.value);
    };

    const handleDatePosted = (e) => {
      setDatePosted(e.currentTarget.value);
    };


    const loginModal = () => {
      return (
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <h4 className='mb-4'>Login to perform the action.</h4>
                  <div className="space-y-6" action="#" method="POST">
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Email address
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          value={requesterEmail} 
                          onChange={(e)=>{setRequesterEmail(e.target.value)}}
                          type="email"
                          autoComplete="email"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="fullname" className="block text-sm font-medium text-gray-700">
                        Your name
                      </label>
                      <div className="mt-1">
                        <input
                          id="fullname"
                          name="fullname"
                          value={requesterName} 
                          onChange={(e)=>{setRequesterName(e.target.value)}}
                          type="text"
                          autoComplete="name"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                        onClick={login}
                      >
                        Continue
                      </button>

                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      );
    }

    const renderLoginLogout = () => {
      if(userContext && userContext.fullName) {
        return (<div>
          <div className='float-left mt-2 mr-2'>Hi {userContext.fullName}</div>
          <button className='flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500' onClick={()=>{localStorage.removeItem("authorization"); props.loginCallback();}}>Logout</button>
          </div>);
      } else {
        return (<button className='flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500' onClick={()=>{setOpen(true);}}>Login</button>);
      }
      
    }

      return (
        <>
        <header className="header-wrapper">
            <div className="container">
                <div className="logo">
                <img src="img/logo.svg" alt="startglobal" />
                </div>
                <div className="entry-info">
                  {renderLoginLogout()}
                </div>
            </div>
        </header>
        <main className="form-panel overflow-scroll h-screen">
        <div className="container">
           <div className="panel-wrapper">
              <div className="row">
                <div className="col-md-2">
                    <div className="filter-wrap sticky-sidebar">
                       <h3 className="title">Filters</h3>
                       <div className="filter-radio">
                          <h6 className="title">CATEGORY</h6>
                          <div className="form-check">
                            <input onChange={handleCategory} value="ALL" checked={category == "ALL"} className="form-check-input" type="radio" name="category_radio" id="all_category"/>
                            <label className="form-check-label" htmlFor="all_category">
                              All categories
                            </label>
                          </div>
                          <div className="form-check">
                            <input onChange={handleCategory} value="OPEN" checked={category == "OPEN"} className="form-check-input" type="radio" name="category_radio" id="open"/>
                            <label className="form-check-label" htmlFor="open">
                              Open
                            </label>
                          </div>
                          <div className="form-check">
                            <input onChange={handleCategory} value="IN_PROGRESS" checked={category == "IN_PROGRESS"} className="form-check-input" type="radio" name="category_radio" id="in_development"/>
                            <label className="form-check-label" htmlFor="in_development">
                              In development
                            </label>
                          </div>
                          <div className="form-check">
                            <input onChange={handleCategory} value="REJECTED" checked={category == "REJECTED"} className="form-check-input" type="radio" name="category_radio" id="rejected" />
                            <label className="form-check-label" htmlFor="rejected">
                              Closed
                            </label>
                          </div>
                          <div className="form-check">
                            <input onChange={handleCategory} value="COMPLETED" checked={category == "COMPLETED"} className="form-check-input" type="radio" name="category_radio" id="in_production" />
                            <label className="form-check-label" htmlFor="in_production">
                              In production
                            </label>
                          </div>
                       </div>
                       {/* <!-- form radio --> */}
                       <div className="filter-radio">
                          <h6 className="title">SORT BY</h6>
                          <div className="form-check">
                            <input onChange={handleSortBy} value="DATE" checked={sortBy == "DATE"} className="form-check-input" type="radio" name="sort_by" id="new_idea"/>
                            <label className="form-check-label" htmlFor="new_idea">
                              New
                            </label>
                          </div>
                          <div className="form-check">
                            <input onChange={handleSortBy} value="VOTES" checked={sortBy == "VOTES"} className="form-check-input" type="radio" name="sort_by" id="popular_idea"/>
                            <label className="form-check-label" htmlFor="popular_idea">
                              Popular ideas
                            </label>
                          </div>
                          <div className="form-check">
                            <input onChange={handleSortBy} value="USER_IDEA" checked={sortBy == "USER_IDEA"} className="form-check-input" type="radio" name="sort_by" id="my_idea"/>
                            <label className="form-check-label" htmlFor="my_idea">
                              My ideas
                            </label>
                          </div>
                       </div>
                       {/* <!-- form radio --> */}
    
                       <div className="filter-radio">
                          <h6 className="title">DATE POSTED</h6>
                          <div className="form-check">
                            <input onChange={handleDatePosted} value="ALL" checked={datePosted == "ALL"}  className="form-check-input" type="radio" name="date_posted" id="any_time"/>
                            <label className="form-check-label" htmlFor="any_time">
                              Any time
                            </label>
                          </div>
                          <div className="form-check">
                            <input onChange={handleDatePosted} value="2022" checked={datePosted == "2022"}  className="form-check-input" type="radio" name="date_posted" id="date_22"/>
                            <label className="form-check-label" htmlFor="date_22">
                              2022
                            </label>
                          </div>
                          <div className="form-check">
                            <input onChange={handleDatePosted} value="2021" checked={datePosted == "2021"}  className="form-check-input" type="radio" name="date_posted" id="date_21"/>
                            <label className="form-check-label" htmlFor="date_21">
                              2021
                            </label>
                          </div>
                          <a href="#"><span>..</span>    Custom date</a>
                       </div>
                       {/* <!-- form radio --> */}
                    </div>
                </div> 
                <div className="col-md-6">
                  <div className="content-wrap">
                    <div className="search-input">
                       <form role="">
                          <div className="input-wrap">
                            <input type="text" name="" className="form-control" placeholder="Search for any requested feature"/>
                            <i className="fa fa-search"></i>
                          </div>
                       </form>    
                    </div>
                    {/* <!-- search input --> */}
    
                    {feedbackIds.map((feedbackId, idx)=>(
                      <PublicFeedbackItem key={idx} feedbackId={feedbackId} loginPopup={()=>setOpen(true)} />
                    ))}
                    <div className='h-12'>
                      <ReactPaginate
                        className='pagination'
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={1}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                      />
                    </div>
                  </div> 
                  {/* <!-- content wrap-->          */}
                </div> 
                <div className="col-md-4">
                  <aside className="form-wrap sticky-sidebar">
                      <div className="card widget">
                         <h3 className="title"> Request new feature</h3>
                         <div>
                            <div className="mb-4">
                              <label htmlFor="featured_title" className="form-label">Feature title</label>
                              <input 
                                  value={featureTitle} 
                                  onChange={(e)=>{setFeatureTitle(e.target.value)}}
                                  type="text" className="form-control" id="featured_title" placeholder=""/>
                            </div>
                            <div className="mb-4">
                              <label for="feature_description" className="form-label">Descrption</label>
                              <textarea  
                                  id="feature_description"
                                  value={featureDescription} 
                                  onChange={(e)=>{setFeatureDescription(e.target.value)}}
                                  className="form-control h-80 text-sm" row="8"></textarea> 
                            </div>
                            {/* <div className="mb-4">
                              <label for="your_name" className="form-label">Your name</label>
                              <input   
                                  value={requesterName} 
                                  onChange={(e)=>{setRequesterName(e.target.value)}}
                                  type="text" className="form-control" id="your_name" placeholder=""/>
                            </div>
                            <div className="mb-4">
                              <label for="your_email" className="form-label">Your Email</label>
                              <input   
                                  value={requesterEmail} 
                                  onChange={(e)=>{setRequesterEmail(e.target.value)}}
                                  type="email" className="form-control" id="your_email" placeholder=""/>
                            </div> */}
                            <button onClick={submitFeedback} className="btn btn-submit">REQUEST FEATURE</button>
                         </div>
                      </div>{/*<!-- widget-->*/}
    
                      <div className="card newsletter">
                         <div className="entry-head">
                           <h4 className="title">Be in the loop</h4>
                           Subscribe to get the latest updates on all our new features.
                         </div>
                         <form role="form" className="row g-2">
                            <div className="bs-col-auto">
                              <input type="email" className="form-control" id="your_email" placeholder="Enter your email" />
                            </div>
                            <div className="bs-col-auto">
                              <button className="btn btn-submit">Subscribe</button>
                            </div>
                         </form>
                      </div>  {/*<!-- widget-->*/}
                  </aside>   
                </div> 
              </div>
           </div>
        </div>
        {loginModal()}
      </main>
      </>
      );
  }