import React, { useContext, useEffect } from 'react';
import Feedback from '../components/Feedback';
import { useParams } from "react-router-dom";


  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


  export default function SingleFeedbackPage(props) {
    const {feedbackId} = useParams();
    return (
    <div className='ml-20'>
      <div className="flex-1 relative z-0 flex overflow-hidden">
          <Feedback feedbackId={feedbackId} />
      </div>
    </div>
    );
  }